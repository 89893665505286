import React, { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react';
import {
    Box,
    Typography,
    TextField,
    Grid,
    InputAdornment,
    Divider,
    Checkbox,
    FormControlLabel,
    Tooltip,
    Radio,
    Skeleton,
    Button
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FaCcVisa, FaCcMastercard, FaCcAmex, FaCcDiscover, FaUniversity } from 'react-icons/fa';
import { SiChase, SiBankofamerica } from 'react-icons/si';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LockIcon from '@mui/icons-material/Lock';
import InputMask from 'react-input-mask';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useNotification } from '../contexts/NotificationContext';
import { PlaidEmbeddedLink } from 'react-plaid-link'; // Using the embedded component

const PaymentForm = forwardRef((props, ref) => {
    const functions = getFunctions();
    const { addNotification } = useNotification();

    // Payment option states – "newCard", saved cards/banks, or "plaid"
    const [selectedOption, setSelectedOption] = useState('newCard');
    const [savedCards, setSavedCards] = useState([]);
    const [customerProfileId, setCustomerProfileId] = useState(null);
    const [customerData, setCustomerData] = useState({});

    // New card fields
    const [cardNumber, setCardNumber] = useState('');
    const [cardHolder, setCardHolder] = useState('');
    const [expiry, setExpiry] = useState('');
    const [cvv, setCvv] = useState('');

    // Billing info
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('United States');

    // Field error states
    const [cardNumberError, setCardNumberError] = useState('');
    const [cardHolderError, setCardHolderError] = useState('');
    const [expiryError, setExpiryError] = useState('');
    const [cvvError, setCvvError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [zipError, setZipError] = useState('');
    const [countryError, setCountryError] = useState('');

    // Checkbox to save payment info (only applicable for new card).
    const [savePaymentInfoChecked, setSavePaymentInfoChecked] = useState(true);
    const [savedBanks, setSavedBanks] = useState([]);

    // Loading state for skeleton loading.
    const [loading, setLoading] = useState(true);

    // Plaid token state – used for bank connection.
    const [plaidToken, setPlaidToken] = useState(null);

    // Fetch saved payment profiles and customer data on mount.
    useEffect(() => {
        const fetchUserData = async () => {
            if (!props.user?.uid) {
                setLoading(false);
                return;
            }
            const userDocRef = doc(db, 'users', props.user.uid);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const userData = docSnap.data();
                setCustomerData(userData);

                // Fetch saved cards from Authorize.net
                if (userData.authorizeNetID) {
                    setCustomerProfileId(userData.authorizeNetID);
                    const getAuthorizeNetProfile = httpsCallable(functions, 'getAuthorizeNetProfile');
                    try {
                        const result = await getAuthorizeNetProfile({ customerId: userData.authorizeNetID });
                        if (result.data?.profiles?.length > 0) {
                            setSavedCards(result.data.profiles);
                            // Optionally, default the selection to the first saved profile
                            setSelectedOption(result.data.profiles[0].customerPaymentProfileId);
                        }
                    } catch (error) {
                        console.error("Error fetching saved payment profiles:", error);
                    }
                }

                // Fetch saved banks from user document (assuming userData.linkedBanks is an array)
                {/* 
                if (userData.linkedBanks && Array.isArray(userData.linkedBanks)) {
                    setSavedBanks(userData.linkedBanks);
                    // Optionally, if you want to select the first bank by default:
                    if (userData.linkedBanks.length > 0 && !selectedOption) {
                        setSelectedOption(userData.linkedBanks[0].bankId);
                    }
                }
                */}
            }
            setLoading(false);
        };
        fetchUserData();
    }, [props.user, functions]);

    // Helper: Replace 'X' with '•'
    const maskXs = (str) => str.replace(/X/g, '•');

    // Helper: Return the appropriate icon for the card type.
    const getCardIcon = (cardType) => {
        switch (cardType?.toLowerCase()) {
            case 'visa':
                return <FaCcVisa size={24} />;
            case 'mastercard':
                return <FaCcMastercard size={24} />;
            case 'amex':
            case 'american express':
                return <FaCcAmex size={24} />;
            case 'discover':
                return <FaCcDiscover size={24} />;
            default:
                return <CreditCardIcon fontSize="medium" />;
        }
    };

    // Helper: Return the appropriate icon for the bank name.
    const getBankIcon = (bankName) => {
        const lowerName = bankName.toLowerCase();
        if (lowerName.includes('chase')) {
            return <SiChase size={24} />;
        } else if (lowerName.includes('bank of america')) {
            return <SiBankofamerica size={24} />;
        } else {
            return <FaUniversity size={24} />;
        }
    };

    // Validate new card and billing fields.
    const validateFields = () => {
        let valid = true;
        setCardNumberError('');
        setCardHolderError('');
        setExpiryError('');
        setCvvError('');
        setAddressError('');
        setCityError('');
        setStateError('');
        setZipError('');
        setCountryError('');

        const cleanedCardNumber = cardNumber.replace(/\s+/g, '');
        if (!/^\d{16}$/.test(cleanedCardNumber)) {
            const errorMsg = 'Card number must be 16 digits.';
            setCardNumberError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        if (!cardHolder.trim()) {
            const errorMsg = 'Cardholder name is required.';
            setCardHolderError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        const [expMonth, expYear] = expiry.split('/');
        if (!expMonth || !expYear || !/^\d{2}$/.test(expMonth) || !/^\d{2}$/.test(expYear)) {
            const errorMsg = 'Expiry must be in MM/YY format.';
            setExpiryError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        } else {
            const monthNum = parseInt(expMonth, 10);
            if (monthNum < 1 || monthNum > 12) {
                const errorMsg = 'Expiry month must be between 01 and 12.';
                setExpiryError(errorMsg);
                addNotification({ message: errorMsg, status: 'error' });
                valid = false;
            }
        }

        if (!/^\d{3}$/.test(cvv)) {
            const errorMsg = 'CVV must be 3 digits.';
            setCvvError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        if (!address.trim()) {
            const errorMsg = 'Billing address is required.';
            setAddressError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        if (!city.trim()) {
            const errorMsg = 'Billing city is required.';
            setCityError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        if (!state.trim()) {
            const errorMsg = 'Billing state is required.';
            setStateError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        if (!zip.trim()) {
            const errorMsg = 'Billing zip is required.';
            setZipError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        if (!country.trim()) {
            const errorMsg = 'Billing country is required.';
            setCountryError(errorMsg);
            addNotification({ message: errorMsg, status: 'error' });
            valid = false;
        }

        return valid;
    };

    // Generate Plaid token when the user selects the Plaid option.
    useEffect(() => {
        if (selectedOption === 'plaid' && !plaidToken) {
            const createPlaidToken = httpsCallable(functions, 'createPlaidLinkToken');
            createPlaidToken({ userId: props.user?.uid })
                .then(result => {
                    const token = result.data.link_token;
                    console.log("Token gotten: ", result.data);
                    setPlaidToken(token);
                })
                .catch(err => {
                    console.error("Error fetching Plaid token:", err);
                    addNotification({ message: 'Error connecting to bank account.', status: 'error' });
                });
        }
    }, [selectedOption, plaidToken, functions, props.user, addNotification]);

    // Callbacks for Plaid events.
    const onPlaidSuccess = useCallback(
        (public_token, metadata) => {
            const saveBankAccount = httpsCallable(functions, 'saveBankAccount');
            saveBankAccount({
                publicToken: public_token,
                bankName: metadata.institution.name,
                userId: props.user?.uid,
            })
                .then((result) => {
                    const newBank = {
                        bankId: result.data.bankId,
                        bankName: metadata.institution.name,
                        maskedAccount: metadata.account.mask ? `•••• ${metadata.account.mask}` : '••••'
                    };
                    setSavedBanks(prev => [...prev, newBank]);
                    setSelectedOption(newBank.bankId);
                    addNotification({
                        message: 'Bank account added successfully. Please confirm to charge your account.',
                        status: 'success'
                    });
                })
                .catch(err => {
                    console.error("Error saving bank account:", err);
                    addNotification({ message: 'Error saving bank account.', status: 'error' });
                });
        },
        [functions, props.user, addNotification]
    );

    const onPlaidEvent = useCallback((eventName, metadata) => {
        console.log("Plaid Event:", eventName, metadata);
    }, []);

    const onPlaidExit = useCallback((err, metadata) => {
        if (err) {
            console.error("Plaid Exit error:", err, metadata);
            addNotification({ message: 'Plaid connection cancelled or failed.', status: 'error' });
        }
    }, [addNotification]);

    // Submit Payment returns a Promise that resolves when payment is successful.
    const handleSubmit = (e) => {
        if (e?.preventDefault) e.preventDefault();

        // Instead of retrieving an amount from props, we now assume the invoice amount is computed on the server.
        if (!props.invoiceId) {
            console.error('Missing invoice ID');
            return Promise.reject(new Error('Missing invoice ID'));
        }

        // If selectedOption is 'newCard', validate the new card fields.
        if (selectedOption === 'newCard' && !validateFields()) {
            return Promise.reject(new Error('Validation failed'));
        }

        // Prevent submission if the user selected the Plaid option but hasn't connected their bank.
        if (selectedOption === 'plaid') {
            const errorMsg = "Please connect your bank account before confirming.";
            addNotification({ message: errorMsg, status: 'error' });
            return Promise.reject(new Error(errorMsg));
        }

        // Gather common customer details.
        const customerInfo = {
            firstName: customerData.firstName || '',
            lastName: customerData.lastName || '',
            phone: customerData.phone || ''
        };

        const generateToken = (secureData) => {
            return new Promise((resolve, reject) => {
                window.Accept.dispatchData(secureData, (response) => {
                    if (response.messages.resultCode === "Error") {
                        let errorText = "Error in processing payment.";
                        if (response.messages.message && response.messages.message.length > 0) {
                            errorText += ` ${response.messages.message[0].text}`;
                        }
                        reject(new Error(errorText));
                    } else {
                        resolve(response.opaqueData.dataValue);
                    }
                });
            });
        };


        // We assume discount codes (an array of discount code IDs) are available via props or state.
        // For example, if they are passed in as a prop:
        const discountCodes = props.discountCodes || [];

        if (selectedOption !== 'newCard') {


            // Process saved banks or saved credit cards.
            const savedBank = savedBanks.find(bank => bank.bankId === selectedOption);
            if (savedBank) {
                const processPlaidBankPayment = httpsCallable(functions, 'processPlaidBankPayment');
                return processPlaidBankPayment({
                    bankId: selectedOption,
                    invoiceId: props.invoiceId,
                    ...customerInfo,
                    discountCodes
                })
                    .then((result) => {
                        console.log("Payment processed using saved bank:", result.data);
                        props.onPaymentSuccess?.(result.data);
                        return result.data;
                    })
                    .catch((error) => {
                        console.error("Error processing payment with saved bank:", error);
                        const errMsg = error?.message || 'Error processing saved bank payment.';
                        addNotification({ message: errMsg, status: 'error' });
                        throw error;
                    });
            } else {
                // Process saved credit cards.
                const selectedCard = savedCards.find(card => card.customerPaymentProfileId === selectedOption);
                const cardLast4 = selectedCard && selectedCard.payment && selectedCard.payment.creditCard
                    ? selectedCard.payment.creditCard.cardNumber.slice(-4)
                    : null;
                const processSavedPayment = httpsCallable(functions, 'processSavedPayment');
                return processSavedPayment({
                    customerProfileId,
                    customerPaymentProfileId: selectedOption,
                    invoiceId: props.invoiceId,
                    cardLast4,
                    ...customerInfo,
                    discountCodes
                })
                    .then((result) => {
                        console.log("Payment processed using saved card:", result.data);
                        props.onPaymentSuccess?.(result.data);
                        return result.data;
                    })
                    .catch((error) => {
                        console.error("Error processing payment with saved card:", error);
                        const errMsg = error?.message || 'Error processing saved card payment.';
                        addNotification({ message: errMsg, status: 'error' });
                        throw error;
                    });
            }
        } else {
            // New card processing.
            const [expMonth, expYear] = expiry.split('/');
            if (!expMonth || !expYear) {
                const errorMsg = 'Invalid expiry date.';
                console.error(errorMsg);
                addNotification({ message: errorMsg, status: 'error' });
                return Promise.reject(new Error(errorMsg));
            }

            // LIVE KEY

            const authData = {
                clientKey: '5TPxQQEcf2e3DAugps7pdsPCF4H273jKAXqn2t8r7YKKSkBJ349Su75dUaf48WfT',
                apiLoginID: '7j9nUR76yt'
            };

            // SANDBOX KEY
            {/*
            const authData = {
                clientKey: '3C6M9XL6sufsv85XbrYKVjnQ84hLSq7AVXWe34f66Mh3bS7feBQ6tWPetxtd2Yu2',
                apiLoginID: '9ZeyG25W'
            };
        */}

            const cardData = {
                cardNumber: cardNumber.replace(/\s+/g, ''),
                month: expMonth,
                year: `20${expYear}`,
                cardCode: cvv,
                zipCode: zip
            };

            const secureData = { authData, cardData };

            // Save the last 4 digits.
            const cardLast4 = cardNumber.replace(/\s+/g, '').slice(-4);

            return generateToken(secureData)
                .then((tokenForPayment) => {
                    const processPayment = httpsCallable(functions, 'processPayment');
                    return processPayment({
                        token: tokenForPayment,
                        cardHolder,
                        address,
                        city,
                        state,
                        zip,
                        country,
                        firstName: customerData.firstName || '',
                        lastName: customerData.lastName || '',
                        phone: customerData.phone || '',
                        invoiceId: props.invoiceId,
                        customerProfileId,
                        cardLast4,
                        discountCodes
                    })
                        .then((result) => {
                            console.log("Payment processed and invoice updated:", result.data);

                            if (savePaymentInfoChecked) {
                                generateToken(secureData)
                                    .then((tokenForProfile) => {
                                        const savePaymentProfile = httpsCallable(functions, 'savePaymentProfile');
                                        return savePaymentProfile({
                                            token: tokenForProfile,
                                            cardHolder,
                                            email: props.user.email,
                                            address,
                                            city,
                                            state,
                                            zip,
                                            country,
                                            cardLast4,
                                            firstName: customerData.firstName || '',
                                            lastName: customerData.lastName || '',
                                            phone: customerData.phone || ''
                                        });
                                    })
                                    .then((resultProfile) => {
                                        console.log("Payment profile saved:", resultProfile.data);
                                    })
                                    .catch((error) => {
                                        console.error("Error saving payment profile:", error);
                                    });
                            }

                            props.onPaymentSuccess?.(result.data);
                            return result.data;
                        })
                        .catch((error) => {
                            console.error("Error processing payment with new card:", error);
                            const errMsg = error?.message || 'Error processing payment.';
                            addNotification({ message: errMsg, status: 'error' });
                            throw error;
                        });
                })
                .catch((err) => {
                    console.error("Error generating token for payment:", err);
                    addNotification({ message: err.message, status: 'error' });
                    return Promise.reject(err);
                });
        }
    };


    // Expose submitPayment to parent via ref.
    useImperativeHandle(ref, () => ({
        submitPayment: () => handleSubmit({ preventDefault: () => { } })
    }));

    if (loading) {
        return (
            <Box
                sx={{
                    mx: 'auto',
                    p: 3,
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}
            >
                <Skeleton variant="text" width="60%" height={30} />
                <Skeleton variant="rectangular" width="100%" height={80} />
                <Skeleton variant="rectangular" width="100%" height={80} />
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton variant="text" width="40%" height={30} />
            </Box>
        );
    }

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                mx: 'auto',
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            {/* Payment Options */}
            <Typography variant="h6">Select Payment Method</Typography>

            {/* Credit/Debit Card Option */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    border: selectedOption === 'newCard' ? '1px solid gray' : '1px solid lightgray',
                    borderRadius: 2,
                    p: 2,
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedOption('newCard')}
            >
                <Radio
                    checked={selectedOption === 'newCard'}
                    onChange={() => setSelectedOption('newCard')}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Credit/Debit Card
                    </Typography>
                </Box>
                <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
                    <FaCcVisa size={24} />
                    <FaCcMastercard size={24} />
                    <FaCcAmex size={24} />
                    <FaCcDiscover size={24} />
                </Box>
            </Box>

            {/* Saved Card Options */}
            {savedCards.map((profile) => {
                const paymentProfileId = profile.customerPaymentProfileId;
                const cardType = profile.payment?.creditCard?.cardType || 'Unknown';
                const originalNumber = profile.payment?.creditCard?.cardNumber || 'XXXX';
                const maskedNumber = maskXs(originalNumber);

                return (
                    <Box
                        key={paymentProfileId}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: selectedOption === paymentProfileId ? '1px solid gray' : '1px solid lightgray',
                            borderRadius: 2,
                            p: 2,
                            cursor: 'pointer'
                        }}
                        onClick={() => setSelectedOption(paymentProfileId)}
                    >
                        <Radio
                            checked={selectedOption === paymentProfileId}
                            onChange={() => setSelectedOption(paymentProfileId)}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                Saved {cardType}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
                            <Typography component="span" color="text.secondary" sx={{ mr: 1 }}>
                                {maskedNumber}
                            </Typography>
                            {getCardIcon(cardType)}
                        </Box>
                    </Box>
                );
            })}

            {/* Saved Bank Options */}
            {savedBanks.map((bank) => {
                const bankId = bank.bankId;
                return (
                    <Box
                        key={bankId}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            border: selectedOption === bankId ? '1px solid gray' : '1px solid lightgray',
                            borderRadius: 2,
                            p: 2,
                            cursor: 'pointer'
                        }}
                        onClick={() => setSelectedOption(bankId)}
                    >
                        <Radio
                            checked={selectedOption === bankId}
                            onChange={() => setSelectedOption(bankId)}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                {bank.bankName}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {bank.maskedAccount}
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 'auto' }}>
                            {getBankIcon(bank.bankName)}
                        </Box>
                    </Box>
                );
            })}

            {/* Plaid (Bank Account) Option 
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    border: selectedOption === 'plaid' ? '1px solid gray' : '1px solid lightgray',
                    borderRadius: 2,
                    p: 2,
                    cursor: 'pointer'
                }}
                onClick={() => setSelectedOption('plaid')}
            >
                <Radio
                    checked={selectedOption === 'plaid'}
                    onChange={() => setSelectedOption('plaid')}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                        Bank Account
                    </Typography>
                </Box>
            </Box>
            */}

            {/* Render input fields based on the selected payment option */}
            {selectedOption === 'newCard' && (
                <>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6">Card Details</Typography>
                        <InputMask
                            mask="9999 9999 9999 9999"
                            value={cardNumber}
                            onChange={(e) => setCardNumber(e.target.value)}
                        >
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    label="Card Number"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    error={!!cardNumberError}
                                    helperText={cardNumberError}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CreditCardIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        </InputMask>
                        <TextField
                            label="Cardholder Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={cardHolder}
                            onChange={(e) => setCardHolder(e.target.value)}
                            error={!!cardHolderError}
                            helperText={cardHolderError}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputMask
                                    mask="99/99"
                                    value={expiry}
                                    onChange={(e) => setExpiry(e.target.value)}
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="Expiry Date (MM/YY)"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            error={!!expiryError}
                                            helperText={expiryError}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CalendarTodayIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                            <Grid item xs={6}>
                                <InputMask
                                    mask="999"
                                    value={cvv}
                                    onChange={(e) => setCvv(e.target.value)}
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="CVV"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            error={!!cvvError}
                                            helperText={cvvError}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <LockIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Box>
                        <Typography variant="h6">Billing Information</Typography>
                        <TextField
                            label="Address"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            error={!!addressError}
                            helperText={addressError}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    error={!!cityError}
                                    helperText={cityError}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    value={state}
                                    onChange={(e) => setState(e.target.value)}
                                    error={!!stateError}
                                    helperText={stateError}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputMask
                                    mask="99999"
                                    value={zip}
                                    onChange={(e) => setZip(e.target.value)}
                                >
                                    {(inputProps) => (
                                        <TextField
                                            {...inputProps}
                                            label="Zip Code"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            error={!!zipError}
                                            helperText={zipError}
                                        />
                                    )}
                                </InputMask>
                            </Grid>
                        </Grid>
                        <TextField
                            label="Country"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                            error={!!countryError}
                            helperText={countryError}
                        />
                    </Box>
                </>
            )}

            {/* Render the embedded Plaid Link view if the bank account option is selected 
            {selectedOption === 'plaid' && (
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Connect your Bank Account</Typography>
                    {plaidToken ? (
                        <PlaidEmbeddedLink
                            token={plaidToken}
                            onSuccess={onPlaidSuccess}
                            onEvent={onPlaidEvent}
                            onExit={onPlaidExit}
                            style={{ width: '100%', height: '500px' }}
                        />
                    ) : (
                        <Typography>Loading bank connection...</Typography>
                    )}
                </Box>
            )}
            */}

            {selectedOption === 'newCard' && (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={savePaymentInfoChecked}
                                onChange={(e) => setSavePaymentInfoChecked(e.target.checked)}
                            />
                        }
                        label="Save my payment information for future purchases."
                    />
                    <Tooltip
                        title="We use Authorize.net to securely process your payment. Only non-sensitive metadata (e.g., transaction ID, masked card number) will be stored for future transactions."
                        arrow
                    >
                        <InfoOutlinedIcon sx={{ ml: 1, color: 'text.secondary' }} />
                    </Tooltip>
                </Box>
            )}

            <Box
                sx={{
                    mt: 2,
                    p: 2,
                    backgroundColor: 'grey.100',
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <InfoOutlinedIcon sx={{ color: 'grey.600', mr: 1 }} />
                <Typography variant="caption" color="text.secondary">
                    Payments are securely processed by <strong>Authorize.net</strong>. We do not store your full payment information on our servers.
                    For more information, please see our Privacy Policy.
                </Typography>
            </Box>
        </Box>
    );
});

export default PaymentForm;

