import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const ContainerLocationEditor = () => {
  const navigate = useNavigate();
  const { universityID, containerLocationID } = useParams();
  const [containerLocation, setContainerLocation] = useState(null);
  const [originalContainerLocation, setOriginalContainerLocation] = useState(null);
  const [changesMade, setChangesMade] = useState(false);

  useEffect(() => {
    const fetchLocation = async () => {
      const locationRef = doc(
        db,
        `universities/${universityID}/containerLocations`,
        containerLocationID
      );
      const locationDoc = await getDoc(locationRef);
      if (locationDoc.exists()) {
        const data = { id: locationDoc.id, ...locationDoc.data() };
        data.parking = data.parking || {}; // Ensure parking object exists
        data.parking.meterBags = data.parking.meterBags || []; // Ensure meterBags is an array
        setContainerLocation(data);
        setOriginalContainerLocation(data);
      }
    };

    fetchLocation();
  }, [universityID, containerLocationID]);

  const autoSave = async (updatedData) => {
    if (updatedData && containerLocation?.id) {
      const locationRef = doc(
        db,
        `universities/${universityID}/containerLocations`,
        containerLocation.id
      );
      await updateDoc(locationRef, updatedData);
      setOriginalContainerLocation(updatedData); // Keep the original state updated
    }
  };

  const handleChange = (field, value) => {
    setContainerLocation((prev) => {
      const updated = { ...prev, [field]: value };
      autoSave(updated); // Save changes automatically
      return updated;
    });
  };

  const handleArrayChange = (arrayField, index, value) => {
    setContainerLocation((prev) => {
      const fieldParts = arrayField.split('.');
      const lastField = fieldParts.pop();
      let nested = prev;

      for (const part of fieldParts) {
        if (!nested[part]) nested[part] = {};
        nested = nested[part];
      }

      if (!Array.isArray(nested[lastField])) nested[lastField] = [];
      const updatedArray = [...nested[lastField]];
      updatedArray[index] = value;

      const updatedState = { ...prev };
      let target = updatedState;

      for (const part of fieldParts) {
        target[part] = { ...target[part] };
        target = target[part];
      }

      target[lastField] = updatedArray;
      autoSave(updatedState); // Save changes automatically
      return updatedState;
    });
  };

  const handleAddToArray = (arrayField, newItem) => {
    setContainerLocation((prev) => {
      const fieldParts = arrayField.split('.');
      const lastField = fieldParts.pop();
      let nested = prev;

      for (const part of fieldParts) {
        if (!nested[part]) nested[part] = {};
        nested = nested[part];
      }

      if (!Array.isArray(nested[lastField])) nested[lastField] = [];
      const updatedArray = [...nested[lastField], newItem];

      const updatedState = { ...prev };
      let target = updatedState;

      for (const part of fieldParts) {
        target[part] = { ...target[part] };
        target = target[part];
      }

      target[lastField] = updatedArray;
      autoSave(updatedState); // Save changes automatically
      return updatedState;
    });
  };

  const handleRemoveFromArray = (arrayField, index) => {
    setContainerLocation((prev) => {
      const fieldParts = arrayField.split('.');
      const lastField = fieldParts.pop();
      let nested = prev;

      for (const part of fieldParts) {
        if (!nested[part]) nested[part] = {};
        nested = nested[part];
      }

      if (!Array.isArray(nested[lastField])) nested[lastField] = [];
      const updatedArray = [...nested[lastField]];
      updatedArray.splice(index, 1);

      const updatedState = { ...prev };
      let target = updatedState;

      for (const part of fieldParts) {
        target[part] = { ...target[part] };
        target = target[part];
      }

      target[lastField] = updatedArray;
      autoSave(updatedState); // Save changes automatically
      return updatedState;
    });
  };

  if (!containerLocation) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Button
        onClick={() => navigate(-1)}
        variant="outlined"
        color="primary"
        sx={{ width: 'auto', mb: 2 }}
      >
        Back
      </Button>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignContent: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h4">Edit Container Location</Typography>

        <Typography
          variant="subtitle2"
          sx={{ verticalAlign: 'center', color: 'gray' }}
        >
          Changes saved automatically.
        </Typography>
      </Box>

      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Location Details
        </Typography>
        <TextField
          label="Location Name"
          fullWidth
          margin="normal"
          value={containerLocation.name}
          onChange={(e) => handleChange('name', e.target.value)}
        />
        {/* New "Shortened Name" TextField */}
        <TextField
          label="Shortened Name"
          fullWidth
          margin="normal"
          value={containerLocation.shortName || ''}
          onChange={(e) => handleChange('shortName', e.target.value)}
        />
        {/* New "Location Group" TextField */}
        <TextField
          label="Location Group"
          fullWidth
          margin="normal"
          value={containerLocation.locationGroup || ''}
          onChange={(e) => handleChange('locationGroup', e.target.value)}
        />
        <TextField
          label="Addresses"
          fullWidth
          margin="normal"
          value={containerLocation.addresses?.join(', ')}
          onChange={(e) =>
            handleChange(
              'addresses',
              e.target.value.split(',').map((a) => a.trim())
            )
          }
        />
        <TextField
          label="Notes"
          fullWidth
          margin="normal"
          value={containerLocation.notes || ''}
          onChange={(e) => handleChange('notes', e.target.value)}
        />
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Serviced Entryways
              </Typography>
              <Button
                variant="outlined"
                onClick={() => handleAddToArray('servicedEntryways', '')}
                startIcon={<Add />}
              >
                Add Serviced Entryway
              </Button>
            </Box>
            {containerLocation.servicedEntryways.length > 0 ? (
              <List>
                {containerLocation.servicedEntryways.map((entryway, idx) => (
                  <ListItem
                    key={idx}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <TextField
                      label="Entryway Name"
                      value={entryway}
                      onChange={(e) =>
                        handleArrayChange(
                          'servicedEntryways',
                          idx,
                          e.target.value
                        )
                      }
                      sx={{ flex: 1, mr: 2 }}
                    />
                    <IconButton
                      edge="end"
                      onClick={() => handleRemoveFromArray('servicedEntryways', idx)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No Serviced Entryways</Typography>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Meter Bags
              </Typography>
              {containerLocation.parking?.meterBagsNeeded && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    handleAddToArray('parking.meterBags', {
                      id: '',
                      requested: false,
                      reserved: false,
                    })
                  }
                  startIcon={<Add />}
                >
                  Add Meter Bag
                </Button>
              )}
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={containerLocation.parking?.meterBagsNeeded || false}
                  onChange={(e) =>
                    handleChange('parking', {
                      ...containerLocation.parking,
                      meterBagsNeeded: e.target.checked,
                    })
                  }
                />
              }
              label="Meter Bags Needed"
            />
            {containerLocation.parking?.meterBagsNeeded && (
              <List>
                {containerLocation.parking.meterBags.map((bag, idx) => (
                  <ListItem key={idx}>
                    <TextField
                      label="Meter Bag ID"
                      value={bag.id}
                      onChange={(e) =>
                        handleArrayChange('parking.meterBags', idx, {
                          ...bag,
                          id: e.target.value,
                        })
                      }
                      sx={{ mr: 2 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bag.requested}
                          onChange={(e) =>
                            handleArrayChange('parking.meterBags', idx, {
                              ...bag,
                              requested: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Requested"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bag.reserved}
                          onChange={(e) =>
                            handleArrayChange('parking.meterBags', idx, {
                              ...bag,
                              reserved: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Reserved"
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() =>
                          handleRemoveFromArray('parking.meterBags', idx)
                        }
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ContainerLocationEditor;
