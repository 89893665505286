import React, { useState, useMemo } from 'react';
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    Divider,
    Checkbox,
    ListItemText,
    Menu,
    MenuItem
} from '@mui/material';
import {
    Search,
    Edit,
    Delete,
    FilterAltOutlined,
    FilterAlt,
    ArrowDropDown,
    ArrowDropUp
} from '@mui/icons-material';

const EmailVerifiedUsers = ({ users = [], theme }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [globalSearch, setGlobalSearch] = useState('');
    const [filters, setFilters] = useState({});
    const [filterAnchors, setFilterAnchors] = useState({});
    const [filterSearch, setFilterSearch] = useState('');
    const [sortOrder, setSortOrder] = useState({});

    const handleTabChange = (e, newIndex) => {
        setTabIndex(newIndex);
        setGlobalSearch('');
        setFilters({});
    };

    // Filter users by current tab, ensure safe fallback
    const tabUsers = useMemo(() => {
        return Array.isArray(users)
            ? users.filter(user => Boolean(user.emailVerified) === (tabIndex === 0))
            : [];
    }, [users, tabIndex]);

    // Global search filter
    const filteredUsers = useMemo(() => {
        if (!globalSearch) return tabUsers;
        return tabUsers.filter(user =>
            Object.values(user || {})
                .filter(v => v !== undefined && v !== null)
                .some(v => v.toString().toLowerCase().includes(globalSearch.toLowerCase()))
        );
    }, [tabUsers, globalSearch]);

    const handleFilterClick = (e, column) => {
        setFilterAnchors(prev => ({ ...prev, [column]: e.currentTarget }));
    };
    const handleFilterClose = column => {
        setFilterAnchors(prev => ({ ...prev, [column]: null }));
        setFilterSearch('');
    };
    const handleFilterSearchChange = e => setFilterSearch(e.target.value);
    const handleFilterChange = (column, value) => {
        setFilters(prev => {
            const list = prev[column] || [];
            const updated = list.includes(value)
                ? list.filter(v => v !== value)
                : [...list, value];
            return { ...prev, [column]: updated };
        });
    };
    const handleSelectAll = column => {
        const values = Array.from(
            new Set(tabUsers.map(u => (u[column] || 'N/A').toString()))
        );
        setFilters(prev => ({ ...prev, [column]: values }));
    };
    const handleDeselectAll = column => {
        setFilters(prev => ({ ...prev, [column]: [] }));
    };
    const handleSort = column => {
        setSortOrder(prev => ({ ...prev, [column]: prev[column] === 'asc' ? 'desc' : 'asc' }));
        // No in-place sort on memo; create new array if needed elsewhere
    };

    const columns = ['firstName', 'lastName', 'email', 'registrationDate', 'status', 'id'];

    return (
        <Box>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Verified" />
                <Tab label="Unverified" />
            </Tabs>

            {/* Global Search */}
            <Box sx={{ mt: 2, mb: 2 }}>
                <TextField
                    placeholder="Search Users..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={globalSearch}
                    onChange={e => setGlobalSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                />
            </Box>

            {/* Table */}
            <TableContainer component="div" sx={{ overflowX: 'auto' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map(col => (
                                <TableCell key={col} sx={{ px: 2, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography fontWeight="bold" sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                            {col.replace(/([A-Z])/g, ' $1').replace(/^./, s => s.toUpperCase())}
                                        </Typography>
                                        <IconButton size="small" onClick={e => handleFilterClick(e, col)} sx={{ fontWeight: filters[col]?.length ? 'bold' : 'normal' }}>
                                            {filters[col]?.length ? <FilterAlt fontSize="small" /> : <FilterAltOutlined fontSize="small" sx={{ strokeWidth: 2 }} />}
                                        </IconButton>
                                        <Menu anchorEl={filterAnchors[col]} open={Boolean(filterAnchors[col])} onClose={() => handleFilterClose(col)}>
                                            <Box sx={{ p: 1 }}>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Search..."
                                                    fullWidth
                                                    size="small"
                                                    value={filterSearch}
                                                    onChange={handleFilterSearchChange}
                                                    InputProps={{ startAdornment: <InputAdornment position="start"><Search fontSize="small" /></InputAdornment> }}
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    <Button size="small" onClick={() => handleSelectAll(col)}>Select All</Button>
                                                    <Button size="small" onClick={() => handleDeselectAll(col)}>Deselect All</Button>
                                                </Box>
                                                <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                    {Array.from(new Set(tabUsers.map(u => (u[col] || 'N/A').toString())))
                                                        .filter(v => v.toLowerCase().includes(filterSearch.toLowerCase()))
                                                        .map(v => (
                                                            <MenuItem key={v} onClick={() => handleFilterChange(col, v)}>
                                                                <Checkbox size="small" checked={filters[col]?.includes(v) || false} />
                                                                <ListItemText primary={v} primaryTypographyProps={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }} />
                                                            </MenuItem>
                                                        ))}
                                                </Box>
                                                <Divider sx={{ my: 1 }} />
                                                <Button size="small" onClick={() => handleSort(col)}>
                                                    {sortOrder[col] === 'asc' ? <ArrowDropUp fontSize="small" /> : <ArrowDropDown fontSize="small" />} Sort
                                                </Button>
                                            </Box>
                                        </Menu>
                                    </Box>
                                </TableCell>
                            ))}
                            <TableCell sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user, index) => (
                            <TableRow key={user.id} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit' }}>
                                {columns.map(col => (
                                    <TableCell key={col} sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}>
                                        {user[col] ?? 'N/A'}
                                    </TableCell>
                                ))}
                                <TableCell sx={{ py: 0 }}>
                                    <IconButton size="small"><Edit fontSize="small" /></IconButton>
                                    <IconButton size="small"><Delete fontSize="small" /></IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default EmailVerifiedUsers;
