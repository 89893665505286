import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    InputAdornment,
    Button,
    Divider,
    Checkbox,
    ListItemText,
} from '@mui/material';
import {
    FilterAltOutlined,
    FilterAlt,
    Search,
    ArrowDropDown,
    ArrowDropUp,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
    collection,
    getDocs,
    query,
    orderBy,
    deleteDoc,
    doc,
} from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust import path to your Firebase config

// Helper function to format a Firestore timestamp into a readable string.
const formatDate = (timestamp) => {
    if (!timestamp?.seconds) return 'N/A';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
};

const SystemMail = () => {
    const theme = useTheme();

    // State to store the fetched mail documents.
    const [mailData, setMailData] = useState([]);

    // Global search state.
    const [globalSearch, setGlobalSearch] = useState('');

    // States for column filtering & sorting.
    const [filters, setFilters] = useState({});
    const [filterAnchors, setFilterAnchors] = useState({});
    const [filterSearch, setFilterSearch] = useState('');
    const [sortOrder, setSortOrder] = useState({});

    // Define the columns for the table.
    const columns = [
        { key: 'to', label: 'To' },
        { key: 'subject', label: 'Subject' },
        { key: 'state', label: 'Status' },
        { key: 'errorMessage', label: 'Error Message' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'id', label: 'ID' },
    ];

    // --- Fetch Mail Documents ---
    useEffect(() => {
        const loadMail = async () => {
            try {
                // Query ordering by creation time (descending).
                const q = query(collection(db, 'mail'), orderBy('createdAt', 'desc'));
                const snapshot = await getDocs(q);

                const mails = snapshot.docs.map((docSnap) => {
                    const data = docSnap.data();
                    let foundError = '';

                    if (data.delivery && data.delivery.error) {
                        foundError = data.delivery.error;
                    }
                    return {
                        id: docSnap.id,
                        to: data.to || 'N/A',
                        subject: data.message?.subject || 'N/A',
                        state: data.delivery?.state || '',
                        errorMessage: foundError,
                        createdAt: data.createdAt ? formatDate(data.createdAt) : 'N/A',
                        attempts: data.attempts ?? 'N/A',
                    };
                });

                setMailData(mails);
            } catch (error) {
                console.error('Error fetching mail documents:', error);
            }
        };

        loadMail();
    }, []);

    // --- Delete All Mail Docs with Errors ---
    const deleteErrorMails = async () => {
        // Confirm deletion to avoid accidental removal.
        if (!window.confirm('Are you sure you want to delete all mail documents with errors?')) {
            return;
        }

        try {
            // Filter out mail documents that have an errorMessage.
            const errorMails = mailData.filter((mail) => mail.errorMessage);
            await Promise.all(
                errorMails.map((mail) =>
                    deleteDoc(doc(db, 'mail', mail.id))
                )
            );

            // Update the local state to remove the deleted mails.
            setMailData((prev) => prev.filter((mail) => !mail.errorMessage));
        } catch (error) {
            console.error('Error deleting mail documents:', error);
        }
    };

    // --- Prepare table rows based on the data we loaded ---
    const rows = mailData;

    // --- Global Search Filtering ---
    const searchedRows = useMemo(() => {
        if (!globalSearch) return rows;
        return rows.filter((row) =>
            Object.values(row)
                .filter((value) => value !== undefined && value !== null)
                .some((value) =>
                    value.toString().toLowerCase().includes(globalSearch.toLowerCase())
                )
        );
    }, [globalSearch, rows]);

    // --- Column Filtering ---
    const filteredRows = searchedRows.filter((row) =>
        Object.keys(filters).every((col) => {
            if (filters[col] && filters[col].length > 0) {
                return filters[col].includes(row[col]);
            }
            return true;
        })
    );

    // --- Sorting Logic ---
    const sortedRows = useMemo(() => {
        let sorted = [...filteredRows];
        const sortColumn = Object.keys(sortOrder).find((col) => sortOrder[col]);
        if (sortColumn) {
            const order = sortOrder[sortColumn];
            sorted.sort((a, b) => {
                if (a[sortColumn] < b[sortColumn]) return order === 'asc' ? -1 : 1;
                if (a[sortColumn] > b[sortColumn]) return order === 'asc' ? 1 : -1;
                return 0;
            });
        }
        return sorted;
    }, [filteredRows, sortOrder]);

    // --- Helpers for Column Filters ---
    const getUniqueColumnValues = (columnKey) => {
        const valuesSet = new Set();
        rows.forEach((row) => {
            valuesSet.add(row[columnKey] || 'N/A');
        });
        return Array.from(valuesSet);
    };

    const handleFilterClick = (event, columnKey) => {
        setFilterAnchors((prev) => ({ ...prev, [columnKey]: event.currentTarget }));
    };

    const handleFilterClose = (columnKey) => {
        setFilterAnchors((prev) => ({ ...prev, [columnKey]: null }));
    };

    const handleFilterSearchChange = (event) => {
        setFilterSearch(event.target.value);
    };

    const handleFilterChange = (columnKey, value) => {
        setFilters((prev) => {
            const current = prev[columnKey] || [];
            let newValues;
            if (current.includes(value)) {
                newValues = current.filter((v) => v !== value);
            } else {
                newValues = [...current, value];
            }
            return { ...prev, [columnKey]: newValues };
        });
    };

    const handleSelectAll = (columnKey) => {
        const allValues = getUniqueColumnValues(columnKey);
        setFilters((prev) => ({ ...prev, [columnKey]: allValues }));
    };

    const handleDeselectAll = (columnKey) => {
        setFilters((prev) => ({ ...prev, [columnKey]: [] }));
    };

    const handleSort = (columnKey) => {
        setSortOrder((prev) => {
            const current = prev[columnKey];
            const newOrder = current === 'asc' ? 'desc' : 'asc';
            return { [columnKey]: newOrder };
        });
    };

    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="h5" gutterBottom>
                    Mail Feed
                </Typography>
                <Button
                    variant="contained"
                    color="error"
                    onClick={deleteErrorMails}
                >
                    Delete Error Mails
                </Button>
            </Box>

            {/* Global Search Bar */}
            <Box sx={{ mb: 2 }}>
                <TextField
                    placeholder="Search Mail..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    value={globalSearch}
                    onChange={(e) => setGlobalSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search fontSize="small" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            <TableContainer
                component={Box}
                sx={{ width: '100%', overflowX: 'auto', WebkitOverflowScrolling: 'touch' }}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.key}
                                    sx={{ pr: 0, pl: 2, fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                            fontWeight="bold"
                                            sx={{ fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                        >
                                            {column.label}
                                        </Typography>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => handleFilterClick(e, column.key)}
                                            sx={{ fontWeight: filters[column.key]?.length ? 'bold' : 'normal' }}
                                        >
                                            {filters[column.key]?.length ? (
                                                <FilterAlt fontSize="small" />
                                            ) : (
                                                <FilterAltOutlined sx={{ strokeWidth: 2 }} fontSize="small" />
                                            )}
                                        </IconButton>
                                        <Menu
                                            anchorEl={filterAnchors[column.key]}
                                            open={Boolean(filterAnchors[column.key])}
                                            onClose={() => handleFilterClose(column.key)}
                                        >
                                            <Box sx={{ p: 1 }}>
                                                <TextField
                                                    variant="outlined"
                                                    placeholder="Search..."
                                                    fullWidth
                                                    value={filterSearch}
                                                    onChange={handleFilterSearchChange}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Search fontSize="small" />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    size="small"
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                                    <Button onClick={() => handleSelectAll(column.key)} size="small">
                                                        Select All
                                                    </Button>
                                                    <Button onClick={() => handleDeselectAll(column.key)} size="small">
                                                        Deselect All
                                                    </Button>
                                                </Box>
                                                <Box sx={{ maxHeight: 200, overflow: 'auto' }}>
                                                    {getUniqueColumnValues(column.key)
                                                        .filter((value) =>
                                                            value.toLowerCase().includes(filterSearch.toLowerCase())
                                                        )
                                                        .map((value) => (
                                                            <MenuItem
                                                                key={value}
                                                                onClick={() => handleFilterChange(column.key, value)}
                                                            >
                                                                <Checkbox
                                                                    checked={filters[column.key]?.includes(value) || false}
                                                                    size="small"
                                                                />
                                                                <ListItemText
                                                                    primary={value}
                                                                    primaryTypographyProps={{
                                                                        fontSize: { xs: '0.7rem', md: '0.9rem' },
                                                                    }}
                                                                />
                                                            </MenuItem>
                                                        ))}
                                                </Box>
                                                <Divider />
                                                <Button onClick={() => handleSort(column.key)} size="small">
                                                    {sortOrder[column.key] === 'asc' ? (
                                                        <ArrowDropUp fontSize="small" />
                                                    ) : (
                                                        <ArrowDropDown fontSize="small" />
                                                    )}
                                                    Sort
                                                </Button>
                                            </Box>
                                        </Menu>
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {sortedRows.map((row, index) => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? theme.palette.action.hover : 'inherit',
                                }}
                            >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.key}
                                        sx={{ py: 0, fontSize: { xs: '0.7rem', md: '0.9rem' } }}
                                    >
                                        {row[column.key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default SystemMail;
