// File: src/pages/NotificationDashboard.jsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Edit, Delete, ContentCopy, Send } from '@mui/icons-material';
import { collection, getDocs, doc, deleteDoc, getDoc } from 'firebase/firestore';
import { httpsCallable, getFunctions } from 'firebase/functions';

import { db } from '../../firebase';
import UserSearch from '../../components/UserSearch';
import { IconPickerItem } from 'react-icons-picker';

const NotificationDashboard = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNotification, setSelectedNotification] = useState(null);

  // For the "Send" dialog
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [notificationToSend, setNotificationToSend] = useState(null);
  // We will store the selected user IDs in this state (passed back from UserSearch)
  const [selectedUsers, setSelectedUsers] = useState([]);

  const functions = getFunctions();

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'notifications'));
        const fetchedNotifications = await Promise.all(
          querySnapshot.docs.map(async (docSnap) => {
            const baseData = { id: docSnap.id, ...docSnap.data() };
            // Check type-specific docs
            const [webDoc, smsDoc, emailDoc] = await Promise.all([
              getDoc(doc(db, 'notificationWeb', docSnap.id)),
              getDoc(doc(db, 'notificationSms', docSnap.id)),
              getDoc(doc(db, 'notificationEmail', docSnap.id)),
            ]);
            return {
              ...baseData,
              hasWeb: webDoc.exists(),
              hasSms: smsDoc.exists(),
              hasEmail: emailDoc.exists(),
            };
          })
        );
        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  // Create or Edit
  const handleCreateNew = () => {
    navigate('new');
  };

  const handleEditBase = (notificationId) => {
    navigate(`edit/${notificationId}`);
  };

  // New: Create Campaign handler
  const handleCreateCampaign = (notificationId) => {
    navigate(`campaign/new/${notificationId}`);
  };

  // Delete notifications
  const handleDelete = async (notificationId) => {
    try {
      // Delete base doc
      await deleteDoc(doc(db, 'notifications', notificationId));
      // Delete type-specific docs if they exist
      const types = ['notificationWeb', 'notificationSms', 'notificationEmail'];
      await Promise.all(
        types.map((typeCollection) =>
          deleteDoc(doc(db, typeCollection, notificationId)).catch((err) => {
            if (err.code !== 'not-found') {
              console.error(`Error deleting ${typeCollection} document:`, err);
            }
          })
        )
      );
      // Update local state
      setNotifications((prev) =>
        prev.filter((notification) => notification.id !== notificationId)
      );
    } catch (error) {
      console.error('Error deleting notification:', error);
    }
  };

  // Copy ID to clipboard
  const copyToClipboard = (id) => {
    navigator.clipboard.writeText(id);
  };

  // Handle "Send" popup
  const openSendDialog = (notification) => {
    setNotificationToSend(notification);
    setSendDialogOpen(true);
  };
  const closeSendDialog = () => {
    setSendDialogOpen(false);
    setNotificationToSend(null);
    setSelectedUsers([]);
  };

  // Called when the user presses "Send Notification"
  const handleSendNotification = async () => {
    if (!notificationToSend?.id || !selectedUsers || selectedUsers.length === 0) {
      console.error("Notification ID or selected users are missing.");
      return;
    }

    console.log('Sending notification', notificationToSend.id, 'to users:', selectedUsers);

    // Calculate the event date (5 seconds from now)
    const eventDate = new Date(Date.now() + 5000).toISOString();

    try {
      // Call the cloud function for each selected user

      const sendNotification = httpsCallable(functions, 'sendNotificationNow');

      await Promise.all(selectedUsers.map(async (customerId) => {
        await sendNotification({
          notificationId: notificationToSend.id,
          customerId,
        });
      }));

      console.log("Notifications successfully sent immediately.");
    } catch (error) {
      console.error("Error sending notifications:", error);
    }

    // Close the dialog
    closeSendDialog();
  };

  const renderIcon = (iconName) => {
    return <IconPickerItem value={iconName} size={24} color="#000" style={{ cursor: 'default' }} />;
  };

  const formatSchedule = (schedule) => {
    if (!schedule) return '-';

    const eventName = schedule.trigger === 'custom' ? schedule.customEventName : schedule.trigger;

    if (schedule.immediate) {
      return `Immediately on ${eventName}`;
    } else {
      const direction = schedule.offsetDirection;
      return `${schedule.offsetValue} ${schedule.offsetUnit} ${direction} ${eventName}`;
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="h4">Notification Dashboard</Typography>
        <Button variant="contained" onClick={handleCreateNew} sx={{ height: '40px' }}>
          Create New Notification
        </Button>
      </Box>

      {/* Notifications Table */}
      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Icon</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Schedule</TableCell>
            <TableCell>Types</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {notifications.map((notification) => (
            <TableRow key={notification.id}>
              {/* ID with Copy */}
              <TableCell>
                <Tooltip title={`Copy ID: ${notification.id}`}>
                  <IconButton onClick={() => copyToClipboard(notification.id)}>
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              </TableCell>

              {/* Icon */}
              <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  {renderIcon(notification.icon)}
                </Box>
              </TableCell>

              {/* Name, Description */}
              <TableCell>{notification.detailName}</TableCell>
              <TableCell>{notification.detailDescription}</TableCell>

              {/* Schedule */}
              <TableCell>{formatSchedule(notification.schedules && notification.schedules[0])}</TableCell>

              {/* Types */}
              <TableCell>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {notification.hasWeb && <Chip label="Web" color="primary" />}
                  {notification.hasSms && <Chip label="SMS" color="secondary" />}
                  {notification.hasEmail && <Chip label="Email" color="success" />}
                </Box>
              </TableCell>

              {/* Actions */}
              <TableCell>
                {/* Send Icon */}
                <Tooltip title="Send Notification">
                  <IconButton onClick={() => openSendDialog(notification)}>
                    <Send />
                  </IconButton>
                </Tooltip>

                {/* Edit Icon */}
                <Tooltip title="Edit Notification">
                  <IconButton onClick={() => handleEditBase(notification.id)}>
                    <Edit />
                  </IconButton>
                </Tooltip>

                {/* Delete Icon */}
                <Tooltip title="Delete Notification">
                  <IconButton onClick={() => setSelectedNotification(notification)}>
                    <Delete />
                  </IconButton>
                </Tooltip>

                {/* Create Campaign Button */}
                <Tooltip title="Create Campaign">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleCreateCampaign(notification.id)}
                    sx={{ ml: 1 }}
                  >
                    Create Campaign
                  </Button>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 2 }}>
        {/* Confirm Deletion Dialog */}
        {selectedNotification && (
          <Dialog open={!!selectedNotification} onClose={() => setSelectedNotification(null)}>
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              Are you sure you want to delete the notification titled "
              {selectedNotification.detailName}"?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSelectedNotification(null)} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDelete(selectedNotification.id);
                  setSelectedNotification(null);
                }}
                color="error"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {/* Send Notification Dialog */}
        <Dialog open={sendDialogOpen} onClose={closeSendDialog} fullWidth maxWidth={"lg"}>
          <DialogTitle>Send Notification</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Select the users to receive: <strong>{notificationToSend?.detailName}</strong>
            </Typography>
            {/* Render the UserSearch component with multi-select mode */}
            <UserSearch onSelectedUsersChange={setSelectedUsers} multiSelect />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSendDialog}>Cancel</Button>
            <Button variant="contained" onClick={handleSendNotification}>
              Send Notification
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default NotificationDashboard;
