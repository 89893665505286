import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Chip,
    Button,
    CircularProgress,
    Divider
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import InvoiceDetails from '../../components/InvoiceDetails';

// Helper function to determine status color
function getStatusColor(status) {
    switch (status.toLowerCase()) {
        case 'paid': return 'success';
        case 'overdue': return 'error';
        case 'pending': return 'default';
        case 'on hold': return 'info';
        case 'due soon': return 'warning';
        case 'cancelled': return 'default';
        default: return 'default';
    }
}

const InvoiceDetailsPage = () => {
    const { invoiceId } = useParams();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState(null);
    const [loading, setLoading] = useState(true);
    const db = getFirestore();
    const functions = getFunctions();

    useEffect(() => {
        const fetchInvoiceDetails = async () => {
            try {
                const fetchInvoice = httpsCallable(functions, 'fetchInvoice');
                const response = await fetchInvoice({ invoiceId });
                const invoiceData = response.data.invoice;
                if (!invoiceData) {
                    console.error("Invoice not found.");
                    return;
                }

                // === Parse dueDate correctly ===
                let dueDateObj = null;
                if (invoiceData.dueDate) {
                    if (typeof invoiceData.dueDate.toDate === 'function') {
                        dueDateObj = invoiceData.dueDate.toDate();
                    } else {
                        dueDateObj = new Date(invoiceData.dueDate);
                    }
                }

                // Pull paid totals if present
                const paidTotals = invoiceData.paymentDetails?.invoiceTotals;

                // Build line items
                let items = [];
                let subtotal = 0;
                let discount = invoiceData.discount || 0;
                let taxRate = invoiceData.taxRate || 0.0635;

                for (const prod of invoiceData.products || []) {
                    const code = typeof prod === 'object' ? (prod.code || prod.id) : prod;
                    const quantity = typeof prod === 'object' ? prod.quantity || 1 : 1;
                    const productSnap = await getDoc(doc(db, "products", code));
                    if (!productSnap.exists()) continue;

                    const productData = productSnap.data();
                    let itemPrice = productData.price || 0;

                    if (productData.productType === "Plan Change") {
                        const fromSnap = await getDoc(doc(db, "products", productData.planFrom));
                        const toSnap = await getDoc(doc(db, "products", productData.planTo));
                        const from = fromSnap.exists ? fromSnap.data().price || 0 : 0;
                        const to = toSnap.exists ? toSnap.data().price || 0 : 0;
                        itemPrice = to - from;
                    }

                    items.push({ name: productData.title || "Unknown Item", price: itemPrice, quantity });
                    subtotal += itemPrice * quantity;
                }

                // Compute fallback totals if not paid
                let discountedSubtotal = subtotal - discount;
                let taxAmount = discountedSubtotal * taxRate;
                let totalAmount = discountedSubtotal + taxAmount;

                // === Build final invoice state ===
                setInvoice({
                    id: invoiceId,
                    status: invoiceData.isPaid
                        ? 'Paid'
                        : invoiceData.isCancelled
                            ? 'Cancelled'
                            : invoiceData.isOverdue
                                ? 'Overdue'
                                : 'Due Soon',
                    dueDate: dueDateObj ? dueDateObj.toLocaleDateString() : "No due date",
                    items,
                    subtotal: paidTotals ? paidTotals.subtotal : subtotal,
                    discount: paidTotals ? paidTotals.discount : discount,
                    discountedSubtotal: paidTotals ? paidTotals.discountedSubtotal : discountedSubtotal,
                    tax: paidTotals ? paidTotals.taxes : taxAmount,
                    total: paidTotals ? paidTotals.total : totalAmount,
                    paymentDetails: invoiceData.paymentDetails || null
                });
            } catch (error) {
                console.error("Error fetching invoice details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchInvoiceDetails();
    }, [invoiceId, db, functions]);

    return (
        <Box sx={{ p: 3 }}>
            <Button
                onClick={() => navigate(-1)}
                variant="outlined"
                color="primary"
                startIcon={<ArrowBack />}
                sx={{ mb: 2 }}
            >
                Back
            </Button>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : invoice ? (
                <>
                    {/* Header */}
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h4" gutterBottom>
                            Invoice Details
                        </Typography>
                    </Box>

                    {/* Status & Due Date */}
                    <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", mb: 2, gap: 2 }}>
                        <Typography variant="subtitle1">
                            Due Date: <strong>{invoice.dueDate}</strong>
                        </Typography>
                        <Typography variant="subtitle1">
                            Status:{' '}
                            <Chip
                                label={invoice.status}
                                color={getStatusColor(invoice.status)}
                                size="small"
                            />
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: "gray" }}>
                            Invoice Code: {invoice.id}
                        </Typography>
                    </Box>

                    <Divider sx={{ mb: 2 }} />

                    {/* Line Items */}
                    <InvoiceDetails items={invoice.items} />

                    <Divider sx={{ my: 3 }} />

                    {/* Price Summary */}
                    <Box
                        sx={{
                            p: 2,
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            backgroundColor: '#f9f9f9',
                            mb: 3
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Overview
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography>Subtotal:</Typography>
                            <Typography>${invoice.subtotal.toFixed(2)}</Typography>
                        </Box>
                        {invoice.discount > 0 && (
                            <>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography>Discount:</Typography>
                                    <Typography>- ${invoice.discount.toFixed(2)}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography>Subtotal after discount:</Typography>
                                    <Typography>${invoice.discountedSubtotal.toFixed(2)}</Typography>
                                </Box>
                            </>
                        )}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography>Estimated Taxes (6.35%):</Typography>
                            <Typography>${invoice.tax.toFixed(2)}</Typography>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            <Typography>Total:</Typography>
                            <Typography>${invoice.total.toFixed(2)}</Typography>
                        </Box>
                    </Box>

                    {/* Payment Information */}
                    {invoice.paymentDetails && (
                        <Box
                            sx={{
                                p: 2,
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                backgroundColor: '#fff'
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                Payment Information
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                {invoice.paymentDetails.transactionId && (
                                    <Typography variant="body1">
                                        Transaction ID:{' '}
                                        <strong>{invoice.paymentDetails.transactionId}</strong>
                                    </Typography>
                                )}
                                {invoice.paymentDetails.cardLast4 && (
                                    <Typography variant="body1">
                                        Card Ending:{' '}
                                        <strong>**** {invoice.paymentDetails.cardLast4}</strong>
                                    </Typography>
                                )}
                                {invoice.paymentDetails.billingInfo && (
                                    <Box>
                                        <Typography variant="body1" gutterBottom>
                                            Billing Information:
                                        </Typography>
                                        <Typography variant="body2">
                                            {invoice.paymentDetails.billingInfo.firstName}{' '}
                                            {invoice.paymentDetails.billingInfo.lastName}
                                        </Typography>
                                        <Typography variant="body2">
                                            {invoice.paymentDetails.billingInfo.address}
                                        </Typography>
                                        <Typography variant="body2">
                                            {invoice.paymentDetails.billingInfo.city},{' '}
                                            {invoice.paymentDetails.billingInfo.state}{' '}
                                            {invoice.paymentDetails.billingInfo.zip}
                                        </Typography>
                                        {invoice.paymentDetails.billingInfo.phone && (
                                            <Typography variant="body2">
                                                Phone: {invoice.paymentDetails.billingInfo.phone}
                                            </Typography>
                                        )}
                                    </Box>
                                )}
                                {invoice.paymentDetails.timePaid && (
                                    <Typography variant="body1">
                                        Time Paid:{' '}
                                        <strong>
                                            {typeof invoice.paymentDetails.timePaid.toDate === 'function'
                                                ? invoice.paymentDetails.timePaid
                                                    .toDate()
                                                    .toLocaleString()
                                                : new Date(invoice.paymentDetails.timePaid)
                                                    .toLocaleString()}
                                        </strong>
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                </>
            ) : (
                <Typography variant="h6" sx={{ mt: 3 }}>
                    Invoice not found.
                </Typography>
            )}
        </Box>
    );
};

export default InvoiceDetailsPage;
