import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    IconButton,
    Grid,
    Paper,
    Link,
    Tabs,
    Tab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stepper,
    Step,
    StepLabel,
    Skeleton,
    CircularProgress,
} from '@mui/material';
import {
    Add,
    Remove,
    Info as InfoIcon,
    Login,
    Logout,
    ReceiptLong,
    CreditCardOff,
    ChangeCircle,
} from '@mui/icons-material';
import { MdOutlineEmojiPeople } from "react-icons/md";
import { FaPeopleCarry } from "react-icons/fa";
import { getFirestore, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Movers = () => {
    // Option states (for manual selection)
    const [selectedOption, setSelectedOption] = useState('self'); // 'self' vs 'movers'
    const [tabValue, setTabValue] = useState(0); // 0: Move Out, 1: Move In

    // Firebase & deadline states
    const { currentUser } = useAuth();
    const db = getFirestore();
    const [activeDeadline, setActiveDeadline] = useState('');
    const [isEarlyValid, setIsEarlyValid] = useState(true);

    // State for mover cost and grid loading flag
    const [moveOutMoverCost, setMoveOutMoverCost] = useState(0);
    const [moveInMoverCost, setMoveInMoverCost] = useState(0);
    const [loadingMoverCost, setLoadingMoverCost] = useState(false);

    // Separate state for Move Out and Move In mover counts
    const [moveOutCount, setMoveOutCount] = useState(0);
    const [moveInCount, setMoveInCount] = useState(0);
    const [initialMoveOutCount, setInitialMoveOutCount] = useState(null);
    const [initialMoveInCount, setInitialMoveInCount] = useState(null);

    // New state variables for paid movers (assuming separate counts per move type)
    const [paidMoveOutCount, setPaidMoveOutCount] = useState(0);
    const [paidMoveInCount, setPaidMoveInCount] = useState(0);

    // Dialog states
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const [confirmStep, setConfirmStep] = useState(0);
    const confirmationSteps = ['Review Request', 'Mover Availability', 'Confirm Request'];

    // New state variables for button loading
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);

    // Derived state: grid is loaded when mover costs have been fetched
    // and initial counts are set for both tabs.
    const gridLoaded =
        initialMoveOutCount !== null &&
        initialMoveInCount !== null &&
        !loadingMoverCost;

    // Determine current mover count and the actual (persisted) requested mover count based on selected tab.
    const currentMoverCount = tabValue === 0 ? moveOutCount : moveInCount;
    const initialCurrentCount = tabValue === 0 ? initialMoveOutCount : initialMoveInCount;
    // Determine current paid mover count based on the active tab.
    const currentPaidCount = tabValue === 0 ? paidMoveOutCount : paidMoveInCount;
    // Compute additional movers being requested.
    const additionalMovers = currentMoverCount - currentPaidCount;
    // Compute initial additional movers (when the component loaded).
    const initialAdditional = initialCurrentCount !== null ? (initialCurrentCount - currentPaidCount) : 0;
    // Choose the mover cost based on tab.
    const moverCost = tabValue === 0 ? moveOutMoverCost : moveInMoverCost;

    // Fetch both mover costs.
    useEffect(() => {
        const fetchMoverCosts = async () => {
            try {
                const moveOutDocRef = doc(db, 'products', '9999');
                const moveInDocRef = doc(db, 'products', '9998');
                const [moveOutSnap, moveInSnap] = await Promise.all([
                    getDoc(moveOutDocRef),
                    getDoc(moveInDocRef)
                ]);
                if (moveOutSnap.exists()) {
                    setMoveOutMoverCost(moveOutSnap.data().price);
                } else {
                    console.error("No such move out product document!");
                }
                if (moveInSnap.exists()) {
                    setMoveInMoverCost(moveInSnap.data().price);
                } else {
                    console.error("No such move in product document!");
                }
            } catch (error) {
                console.error("Error fetching mover costs:", error);
            }
        };

        setLoadingMoverCost(true);
        fetchMoverCosts().finally(() => setLoadingMoverCost(false));
    }, [db]);

    // Fetch registration deadlines.
    useEffect(() => {
        const fetchDeadline = async () => {
            if (!currentUser) return;
            try {
                const userDocRef = doc(db, 'users', currentUser.uid);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const userData = userDocSnap.data();
                    if (userData.universityId) {
                        const uniRef = doc(db, 'universities', userData.universityId);
                        const uniSnap = await getDoc(uniRef);
                        if (uniSnap.exists()) {
                            const { earlyRegistrationDeadline, lateRegistrationDeadline } = uniSnap.data();
                            const now = new Date();
                            const earlyDate = earlyRegistrationDeadline?.toDate?.();
                            const lateDate = lateRegistrationDeadline?.toDate?.();
                            if (earlyDate && earlyDate > now) {
                                setIsEarlyValid(true);
                                setActiveDeadline(
                                    earlyDate.toLocaleString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric',
                                    }) + " at 11:59pm"
                                );
                            } else if (lateDate) {
                                setIsEarlyValid(false);
                                setActiveDeadline(
                                    lateDate.toLocaleString('en-US', {
                                        month: 'long',
                                        day: 'numeric',
                                        year: 'numeric',
                                    }) + " at 11:59pm"
                                );
                            } else {
                                setActiveDeadline('');
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching deadline:", error);
            }
        };
        fetchDeadline();
    }, [currentUser, db]);

    // Subscribe to the user document once to get both move counts and paid mover counts.
    useEffect(() => {
        if (!currentUser) return;
        const userDocRef = doc(db, 'users', currentUser.uid);
        const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const newMoveOutCount = userData.requestedMoversMoveOut || 0;
                const newMoveInCount = userData.requestedMoversMoveIn || 0;
                const newPaidMoveOutCount = userData.paidMoversMoveOut || 0;
                const newPaidMoveInCount = userData.paidMoversMoveIn || 0;
                setMoveOutCount(newMoveOutCount);
                setMoveInCount(newMoveInCount);
                setPaidMoveOutCount(newPaidMoveOutCount);
                setPaidMoveInCount(newPaidMoveInCount);
                if (initialMoveOutCount === null) {
                    setInitialMoveOutCount(newMoveOutCount);
                }
                if (initialMoveInCount === null) {
                    setInitialMoveInCount(newMoveInCount);
                }
            }
        });
        return () => unsubscribe();
    }, [currentUser, db, initialMoveOutCount, initialMoveInCount]);

    // Set the initial selected option based on the user's actual requested movers (from Firebase)
    useEffect(() => {
        if (initialCurrentCount !== null) {
            setSelectedOption(initialCurrentCount === 0 ? 'self' : 'movers');
        }
    }, [initialCurrentCount, tabValue]);

    // Helper to refresh the entire panel by re-fetching the user's data.
    const refreshPanel = async () => {
        if (!currentUser) return;
        try {
            const userDocRef = doc(db, 'users', currentUser.uid);
            const docSnap = await getDoc(userDocRef);
            if (docSnap.exists()) {
                const userData = docSnap.data();
                const newMoveOutCount = userData.requestedMoversMoveOut || 0;
                const newMoveInCount = userData.requestedMoversMoveIn || 0;
                setMoveOutCount(newMoveOutCount);
                setMoveInCount(newMoveInCount);
                setInitialMoveOutCount(newMoveOutCount);
                setInitialMoveInCount(newMoveInCount);
                if (tabValue === 0) {
                    setSelectedOption(newMoveOutCount === 0 ? 'self' : 'movers');
                } else {
                    setSelectedOption(newMoveInCount === 0 ? 'self' : 'movers');
                }
            }
        } catch (error) {
            console.error("Error refreshing panel:", error);
        }
    };

    // Modified handler for manual option selection.
    const handleOptionSelect = (option) => {
        if (option === 'self' && currentMoverCount > 0) {
            openCancelDialog();
        } else {
            setSelectedOption(option);
        }
    };

    // Update count functions that work based on the current tab.
    const incrementCount = () => {
        if (tabValue === 0) {
            setMoveOutCount(prev => prev + 1);
        } else {
            setMoveInCount(prev => prev + 1);
        }
    };

    // Prevent decrementing below the number of paid movers.
    const decrementCount = () => {
        if (tabValue === 0) {
            setMoveOutCount(prev => (prev > paidMoveOutCount ? prev - 1 : prev));
        } else {
            setMoveInCount(prev => (prev > paidMoveInCount ? prev - 1 : prev));
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // TabPanel component.
    const TabPanel = ({ children, value, index, ...other }) => (
        <div
            role="tabpanel"
            id={`movers-tabpanel-${index}`}
            aria-labelledby={`movers-tab-${index}`}
            {...other}
            style={{ display: value === index ? 'block' : 'none' }}
        >
            <Box sx={{ pt: 2 }}>
                {children}
            </Box>
        </div>
    );

    // Dialog handlers for confirmation.
    const openConfirmDialog = () => {
        setConfirmDialogOpen(true);
        setConfirmStep(0);
    };

    const handleCancelConfirmDialog = () => {
        setConfirmDialogOpen(false);
    };

    const handleNextStep = () => {
        setConfirmStep(prev => prev + 1);
    };

    const handleBackStep = () => {
        setConfirmStep(prev => prev - 1);
    };

    // Cancel dialog handlers.
    const openCancelDialog = () => {
        setCancelDialogOpen(true);
    };

    const handleCancelDialogClose = () => {
        setCancelDialogOpen(false);
    };

    const handleConfirmCancel = async () => {
        setCancelLoading(true);
        const functions = getFunctions();
        const requestMoversFn = httpsCallable(functions, 'requestMovers');
        try {
            await requestMoversFn({
                moverCount: 0,
                moveType: tabValue === 0 ? 'Move Out' : 'Move In',
            });
        } catch (error) {
            console.error('Error calling requestMovers cloud function:', error);
        } finally {
            setCancelLoading(false);
            setCancelDialogOpen(false);
            refreshPanel();
        }
    };

    // Full confirmation dialog handler.
    const handleConfirmRequest = async () => {
        setConfirmLoading(true);
        const functions = getFunctions();
        const requestMoversFn = httpsCallable(functions, 'requestMovers');
        try {
            await requestMoversFn({
                moverCount: currentMoverCount,
                moveType: tabValue === 0 ? 'Move Out' : 'Move In',
            });
        } catch (error) {
            console.error('Error calling requestMovers cloud function:', error);
        } finally {
            setConfirmLoading(false);
            setConfirmDialogOpen(false);
            refreshPanel();
        }
    };

    // Determine button label.
    // If no changes have been made, show "Selected".
    // If the current additional movers (current - paid) is zero but initially additional was non-zero, show "Cancel Add".
    // If current total is zero and initially non-zero, show "Cancel Movers".
    // Otherwise, show "Add Movers" if paid movers exist, or "Request Movers" if none.
    const buttonText = (initialCurrentCount !== null && currentMoverCount === initialCurrentCount)
        ? "Selected"
        : (additionalMovers === 0 && initialAdditional > 0
            ? "Cancel Add"
            : (currentMoverCount === 0 && initialCurrentCount > 0
                ? "Cancel Movers"
                : (currentPaidCount > 0 ? "Add Movers" : "Request Movers")));

    return (
        <Box sx={{ p: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Movers
            </Typography>
            <Grid container spacing={4} alignItems="flex-start">
                {/* Left Column: Image */}
                <Grid item xs={12} md={6}>
                    <Box
                        component="img"
                        src="/images/storage_image.webp"
                        alt="Student Movers"
                        sx={{ width: '100%', borderRadius: 0 }}
                    />
                </Grid>
                {/* Right Column: Options */}
                <Grid item xs={12} md={6}>
                    {!gridLoaded ? (
                        <Skeleton variant="rectangular" width="100%" height={600} />
                    ) : (
                        <Paper sx={{ p: 4 }}>
                            <Typography variant="h5" gutterBottom>
                                How it Works
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                We optionally offer student movers recruited from your university to assist with moving your belongings to and from our drop off locations.
                            </Typography>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                variant="fullWidth"
                                sx={{ mt: 2 }}
                            >
                                <Tab icon={<Logout />} label="Move Out" id="movers-tab-0" aria-controls="movers-tabpanel-0" />
                                <Tab icon={<Login />} label="Move In" id="movers-tab-1" aria-controls="movers-tabpanel-1" />
                            </Tabs>
                            {/* Tab Panel for Move Out */}
                            <TabPanel value={tabValue} index={0}>
                                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                    <Button
                                        variant={selectedOption === 'self' ? 'contained' : 'outlined'}
                                        onClick={() => handleOptionSelect('self')}
                                        sx={{
                                            flex: 1,
                                            height: '100px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MdOutlineEmojiPeople style={{ fontSize: '2rem' }} />
                                        <Typography variant="button" sx={{ mt: 1 }}>
                                            I can move my items
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant={selectedOption === 'movers' ? 'contained' : 'outlined'}
                                        onClick={() => setSelectedOption('movers')}
                                        sx={{
                                            flex: 1,
                                            height: '100px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaPeopleCarry style={{ fontSize: '2rem' }} />
                                        <Typography variant="button" sx={{ mt: 1 }}>
                                            I'll need movers
                                        </Typography>
                                    </Button>
                                </Box>
                                {selectedOption === 'self' ? (
                                    <Paper sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#e3f2fd', p: 2, mt: 2 }}>
                                        <InfoIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="body1">
                                            This is a great option for students looking to save! Consider asking friends to help move your items.
                                        </Typography>
                                    </Paper>
                                ) : (
                                    <>
                                        <Paper sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#e3f2fd', p: 2, mt: 2 }}>
                                            <InfoIcon color="primary" sx={{ mr: 1 }} />
                                            <Typography variant="body1">
                                                Our student movers are available at <strong>${moverCost} per mover, per move</strong>.
                                            </Typography>
                                        </Paper>
                                        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                                            How many movers will you need for Move Out?*
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 1 }}>
                                            <IconButton
                                                onClick={decrementCount}
                                                color="primary"
                                                disabled={currentMoverCount <= currentPaidCount}
                                            >
                                                <Remove />
                                            </IconButton>
                                            <Typography variant="h6">{currentMoverCount}</Typography>
                                            <IconButton onClick={incrementCount} color="primary">
                                                <Add />
                                            </IconButton>
                                        </Box>
                                        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                                            You've paid for {currentPaidCount} mover{currentPaidCount !== 1 && 's'}.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                onClick={
                                                    (currentMoverCount === 0 && initialCurrentCount > 0)
                                                        ? openCancelDialog
                                                        : openConfirmDialog
                                                }
                                                disabled={initialCurrentCount !== null && currentMoverCount === initialCurrentCount}
                                            >
                                                {buttonText}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </TabPanel>
                            {/* Tab Panel for Move In */}
                            <TabPanel value={tabValue} index={1}>
                                <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                                    <Button
                                        variant={selectedOption === 'self' ? 'contained' : 'outlined'}
                                        onClick={() => handleOptionSelect('self')}
                                        sx={{
                                            flex: 1,
                                            height: '100px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <MdOutlineEmojiPeople style={{ fontSize: '2rem' }} />
                                        <Typography variant="button" sx={{ mt: 1 }}>
                                            I can move my items
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant={selectedOption === 'movers' ? 'contained' : 'outlined'}
                                        onClick={() => setSelectedOption('movers')}
                                        sx={{
                                            flex: 1,
                                            height: '100px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaPeopleCarry style={{ fontSize: '2rem' }} />
                                        <Typography variant="button" sx={{ mt: 1 }}>
                                            I'll need movers
                                        </Typography>
                                    </Button>
                                </Box>
                                {selectedOption === 'self' ? (
                                    <Paper sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#e3f2fd', p: 2, mt: 2 }}>
                                        <InfoIcon color="primary" sx={{ mr: 1 }} />
                                        <Typography variant="body1">
                                            This is a great option for students looking to save! Consider asking friends to help move your items.
                                        </Typography>
                                    </Paper>
                                ) : (
                                    <>
                                        <Paper sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#e3f2fd', p: 2, mt: 2 }}>
                                            <InfoIcon color="primary" sx={{ mr: 1 }} />
                                            <Typography variant="body1">
                                                Our student movers are available at <strong>${moverCost} per mover, per move</strong>.
                                            </Typography>
                                        </Paper>
                                        <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                                            How many movers will you need for Move In?*
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mt: 1 }}>
                                            <IconButton
                                                onClick={decrementCount}
                                                color="primary"
                                                disabled={currentMoverCount <= currentPaidCount}
                                            >
                                                <Remove />
                                            </IconButton>
                                            <Typography variant="h6">{currentMoverCount}</Typography>
                                            <IconButton onClick={incrementCount} color="primary">
                                                <Add />
                                            </IconButton>
                                        </Box>
                                        <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                                            You've paid for {currentPaidCount} mover{currentPaidCount !== 1 && 's'}.
                                        </Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                            <Button
                                                variant="contained"
                                                onClick={
                                                    (currentMoverCount === 0 && initialCurrentCount > 0)
                                                        ? openCancelDialog
                                                        : openConfirmDialog
                                                }
                                                disabled={initialCurrentCount !== null && currentMoverCount === initialCurrentCount}
                                            >
                                                {buttonText}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </TabPanel>
                            <Typography variant="caption" align="center" sx={{ display: 'block', mt: 2 }}>
                                *Mover availability is subject to capacity constraints and allocated on a first-come, first-served basis. <br />
                                <Link href="/movers-info" underline="hover">
                                    Learn More.
                                </Link>
                            </Typography>
                        </Paper>
                    )}
                </Grid>
            </Grid>

            {/* Multi-step confirmation dialog */}
            <Dialog
                open={confirmDialogOpen}
                onClose={handleCancelConfirmDialog}
                fullWidth
                maxWidth="md"
                PaperProps={{ sx: { transition: 'width 0.5s ease, height 0.5s ease' } }}
            >
                <DialogTitle>Confirm Mover Request</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={confirmStep} sx={{ mb: 2 }}>
                        {confirmationSteps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {confirmStep === 0 && (
                        <Box>
                            <Typography variant="body1" align="center" paragraph>
                                You are requesting <strong>{additionalMovers}</strong> additional mover{additionalMovers !== 1 && 's'} for <strong>{tabValue === 0 ? 'Move Out' : 'Move In'}</strong>.
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    gap: 2,
                                    mb: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#f5f5f5',
                                        textAlign: 'center',
                                        p: 2,
                                        minWidth: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ReceiptLong sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Payment Due
                                    </Typography>
                                    <Typography variant="body2">
                                        A new invoice will be created for your mover request.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#f5f5f5',
                                        textAlign: 'center',
                                        p: 2,
                                        minWidth: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <CreditCardOff sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Not Auto-Charged
                                    </Typography>
                                    <Typography variant="body2">
                                        Pay by {activeDeadline || 'the deadline'}.
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        flex: 1,
                                        backgroundColor: '#f5f5f5',
                                        textAlign: 'center',
                                        p: 2,
                                        minWidth: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <ChangeCircle sx={{ fontSize: 40, mb: 1, color: 'primary.main' }} />
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                        Change Anytime
                                    </Typography>
                                    <Typography variant="body2">
                                        Free changes until {activeDeadline || 'the deadline'}.
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                    {confirmStep === 1 && (
                        <Box>
                            <Paper sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", backgroundColor: '#e3f2fd', p: 2, mt: 2, mb: 3 }}>
                                <InfoIcon color="primary" sx={{ mr: 1 }} />
                                <Box>
                                    <Typography variant="body1" align="center">
                                        <strong>Please note: </strong>Mover availability may be limited.
                                    </Typography>
                                </Box>
                            </Paper>
                            <Typography variant="body2" align="center">
                                Our team will notify you before your move out date if movers become unavailable.
                            </Typography>
                        </Box>
                    )}
                    {confirmStep === 2 && (
                        <Box>
                            <Typography variant="body1" align="center" paragraph>
                                After confirming, your mover request will be processed:
                            </Typography>
                            <Typography variant="body2" align="center">
                                Cost: <strong>${moverCost} per mover, per move</strong>
                            </Typography>
                            <Typography variant="body2" align="center">
                                Additional Movers: <strong>{additionalMovers}</strong>
                            </Typography>
                            <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                                Total: <strong>${moverCost * additionalMovers}</strong>
                            </Typography>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelConfirmDialog} color="inherit">
                        Cancel
                    </Button>
                    {confirmStep > 0 && (
                        <Button onClick={handleBackStep} color="inherit">
                            Back
                        </Button>
                    )}
                    {confirmStep < confirmationSteps.length - 1 ? (
                        <Button onClick={handleNextStep} variant="contained" color="primary">
                            Continue
                        </Button>
                    ) : (
                        <Button
                            onClick={handleConfirmRequest}
                            variant="contained"
                            color="primary"
                            disabled={confirmLoading}
                        >
                            {confirmLoading ? <CircularProgress size={24} /> : 'Confirm'}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Simplified cancel dialog */}
            <Dialog
                open={cancelDialogOpen}
                onClose={handleCancelDialogClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>Cancel Movers</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to cancel movers and switch to moving your items yourself?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDialogClose} color="inherit">
                        No, Keep Movers
                    </Button>
                    <Button
                        onClick={handleConfirmCancel}
                        variant="contained"
                        color="primary"
                        disabled={cancelLoading}
                    >
                        {cancelLoading ? <CircularProgress size={24} /> : 'Yes, Cancel Movers'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Movers;
