import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Chip,
    Button,
    Skeleton,
    Checkbox,
    FormControlLabel,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Visibility, PaymentOutlined } from '@mui/icons-material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

// Helper function to determine status color
function getStatusColor(status) {
    switch (status.toLowerCase()) {
        case 'paid': return 'success';
        case 'overdue': return 'error';
        case 'pending': return 'default';
        case 'on hold': return 'info';
        case 'due soon': return 'warning';
        case 'cancelled': return 'default';
        default: return 'default';
    }
}

function formatInvoiceDate(dateValue) {
    if (!dateValue) return "No date available";
    let dateObj;
    if (dateValue.toDate && typeof dateValue.toDate === 'function') {
        dateObj = dateValue.toDate();
    } else if (dateValue._seconds) {
        dateObj = new Date(dateValue._seconds * 1000);
    } else {
        dateObj = new Date(dateValue);
    }
    if (isNaN(dateObj.getTime())) {
        return "Invalid date";
    }
    return format(dateObj, 'dd MMM yyyy');
}

const Billing = () => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showCancelled, setShowCancelled] = useState(false);
    const navigate = useNavigate();
    const db = getFirestore();

    // Media query hook for mobile responsiveness.
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchUserInvoices = async () => {
            try {
                const functions = getFunctions();
                const fetchInvoices = httpsCallable(functions, 'fetchInvoices');
                const response = await fetchInvoices();
                const invoiceDocs = response.data.invoices;

                const fetchInvoiceDetails = invoiceDocs.map(async (invoice) => {
                    // For due payments, we want to use the dueDate.
                    // For past payments, use paidDate if available, otherwise cancelledDate.
                    const paymentDate = invoice.paidDate || invoice.cancelledDate || invoice.dueDate;
                    const formattedDate = formatInvoiceDate(paymentDate);

                    const products = invoice.products || [];
                    let totalAmount = 0;

                    for (const prod of products) {
                        const code = typeof prod === 'object' ? prod.code : prod;
                        const quantity = typeof prod === 'object' ? prod.quantity || 1 : 1;

                        const productRef = doc(db, "products", code);
                        const productSnap = await getDoc(productRef);

                        if (productSnap.exists()) {
                            let productData = productSnap.data();

                            // If product type is "Plan Change", recalc the price
                            if (productData.productType === "Plan Change") {
                                const planFromRef = doc(db, "products", productData.planFrom);
                                const planToRef = doc(db, "products", productData.planTo);

                                const planFromSnap = await getDoc(planFromRef);
                                const planToSnap = await getDoc(planToRef);

                                const planFromPrice = planFromSnap.exists() ? (planFromSnap.data().price || 0) : 0;
                                const planToPrice = planToSnap.exists() ? (planToSnap.data().price || 0) : 0;

                                // Calculate the price difference
                                productData.price = planToPrice - planFromPrice;
                            }

                            totalAmount += (productData.price || 0) * quantity;
                        }
                    }

                    return {
                        id: invoice.id,
                        date: formattedDate,
                        title: invoice.title,
                        amount: totalAmount,
                        status: invoice.isPaid
                            ? 'Paid'
                            : invoice.isCancelled
                                ? 'Cancelled'
                                : invoice.isOverdue
                                    ? 'Overdue'
                                    : 'Due Soon'
                    };
                });

                const invoicesWithProducts = await Promise.all(fetchInvoiceDetails);
                setInvoices(invoicesWithProducts);
            } catch (error) {
                console.error("Error fetching invoices:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchUserInvoices();
    }, [db]);

    // Filtering logic:
    // Always show paid invoices.
    // Show cancelled invoices only if the checkbox is checked.
    const pastPayments = invoices.filter(invoice => {
        const status = invoice.status.toLowerCase();
        if (status === 'paid') return true;
        if (status === 'cancelled') return showCancelled;
        return false;
    });

    const duePayments = invoices.filter(invoice =>
        invoice.status.toLowerCase() !== 'paid' && invoice.status.toLowerCase() !== 'cancelled'
    );

    // Render a skeleton table that mimics the header and a fixed number of rows.
    const renderSkeletonTable = (headerCells, rowCount = 5) => (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {headerCells.map((cell, idx) => (
                            <TableCell key={idx} sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>
                                <Skeleton variant="text" width="80%" />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.from(new Array(rowCount)).map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {headerCells.map((_, colIndex) => (
                                <TableCell key={colIndex} sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>
                                    <Skeleton variant="text" />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Box sx={{ p: isMobile ? 0 : 3, ml: isMobile ? 0 : 3 }}>
            <Typography variant={isMobile ? "h5" : "h4"}>My Payments</Typography>
            <Typography variant={isMobile ? "body2" : "subtitle1"} sx={{ mb: 3 }}>
                View and manage your payments for SummerStore services.
            </Typography>
            {loading ? (
                <>
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2, fontSize: isMobile ? '0.75rem' : 'inherit' }}
                    >
                        Due Payments
                    </Typography>
                    {renderSkeletonTable(
                        isMobile
                            ? ['Description', 'Due Date', 'Status', 'Action']
                            : ['Description', 'Amount', 'Due Date', 'Status', 'Action'], 5
                    )}
                    <Box sx={{ mt: 4 }} />
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2, fontSize: isMobile ? '0.75rem' : 'inherit' }}
                    >
                        Past Payments
                    </Typography>
                    {renderSkeletonTable(
                        isMobile
                            ? ['Description', 'Status Changed', 'Status', 'Action']
                            : ['Description', 'Amount', 'Status Changed', 'Status', 'Action'], 5
                    )}
                </>
            ) : (
                <>
                    {/* Due Payments Table */}
                    <Box sx={{ mt: 4 }}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            sx={{ color: 'text.secondary', textTransform: 'uppercase', mb: 2, fontSize: isMobile ? '0.75rem' : 'inherit' }}
                        >
                            Due Payments
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Description</TableCell>
                                        {!isMobile && <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Amount</TableCell>}
                                        <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Due Date</TableCell>
                                        <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Status</TableCell>
                                        <TableCell align="right" sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {duePayments.map((invoice) => (
                                        <TableRow key={invoice.id}>
                                            <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>{invoice.title}</TableCell>
                                            {!isMobile && <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>${invoice.amount.toFixed(2)}</TableCell>}
                                            <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>{invoice.date}</TableCell>
                                            <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>
                                                <Chip label={invoice.status} color={getStatusColor(invoice.status)} size="small" />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<PaymentOutlined />}
                                                    onClick={() => navigate(`invoice/pay/${invoice.id}`)}
                                                    sx={{ mr: 1, fontSize: isMobile ? '0.65rem' : 'inherit', mb: isMobile ? 1 : 0 }}
                                                >
                                                    Pay
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<Visibility />}
                                                    onClick={() => navigate(`invoice/details/${invoice.id}`)}
                                                    sx={{ fontSize: isMobile ? '0.65rem' : 'inherit' }}
                                                >
                                                    {isMobile ? "View" : "View Details"}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {/* Past Payments Table */}
                    <Box sx={{ mt: 4 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography
                                variant="subtitle2"
                                sx={{ color: 'text.secondary', textTransform: 'uppercase', fontSize: isMobile ? '0.75rem' : 'inherit' }}
                            >
                                Past Payments
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showCancelled}
                                        onChange={(e) => setShowCancelled(e.target.checked)}
                                        color="primary"
                                        sx={{ p: isMobile ? 0.5 : 1 }}
                                    />
                                }
                                label="Show cancelled invoices"
                                sx={{ color: "gray", fontSize: isMobile ? '0.65rem' : 'inherit' }}
                            />
                        </Box>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Description</TableCell>
                                        <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Status Changed</TableCell>
                                        <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Status</TableCell>
                                        <TableCell align="right" sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pastPayments.map((invoice) => (
                                        <TableRow key={invoice.id}>
                                            <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>{invoice.title}</TableCell>
                                            <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>{invoice.date}</TableCell>
                                            <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>
                                                <Chip label={invoice.status} color={getStatusColor(invoice.status)} size="small" />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    startIcon={<Visibility />}
                                                    onClick={() => navigate(`invoice/details/${invoice.id}`)}
                                                    sx={{ fontSize: isMobile ? '0.65rem' : 'inherit' }}
                                                >
                                                    {isMobile ? "View" : "View Details"}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default Billing;
