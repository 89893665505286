import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import {
  People as PeopleIcon,
  Assessment as AssessmentIcon,
  AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
} from 'recharts';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path as needed

const AdminDashboard = () => {
  // Helper functions for dates
  const getFormattedDate = (date) => date.toISOString().split('T')[0];
  const getReadableDate = (date) => new Date(date).toLocaleDateString('en-US');

  // Set end date to 3 days from now and start date to one month prior
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 3);
  const oneMonthAgo = new Date(tomorrow);
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  const [userDocs, setUserDocs] = useState([]);
  const [cumulativeUserData, setCumulativeUserData] = useState([]);
  const [planData, setPlanData] = useState([]);
  // products mapping: product code (storagePlan value) => { internalName, price }
  const [products, setProducts] = useState({});
  const [startDate, setStartDate] = useState(getFormattedDate(oneMonthAgo));
  const [endDate, setEndDate] = useState(getFormattedDate(tomorrow));

  // NEW: University and container locations state
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("university_1");
  const [containerLocations, setContainerLocations] = useState([]);
  const [containerLocationChartData, setContainerLocationChartData] = useState([]);
  // NEW: Cache container appointments by container location id
  const [containerAppointments, setContainerAppointments] = useState({});

  // NEW: States for appointment charts
  const [moveInChartData, setMoveInChartData] = useState([]);
  const [moveOutChartData, setMoveOutChartData] = useState([]);

  // Fetch users from Firebase
  const fetchUserDocs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'users'));
      const users = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Assuming registrationDate is a Firebase Timestamp
        users.push({
          ...data,
          id: doc.id,
          registrationDate: data.registrationDate.toDate()
        });
      });
      setUserDocs(users);
    } catch (error) {
      console.error("Error fetching user docs: ", error);
    }
  };

  // Fetch and cache products from Firebase
  const fetchProductDocs = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productMapping = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        // Now storing both internalName and price (assuming data.price exists)
        productMapping[doc.id] = {
          internalName: data.internalName,
          price: data.price
        };
      });
      setProducts(productMapping);
    } catch (error) {
      console.error("Error fetching product docs: ", error);
    }
  };

  // Fetch universities from Firebase
  const fetchUniversities = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'universities'));
      const uniList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        uniList.push({
          id: doc.id,
          name: data.name,
        });
      });
      setUniversities(uniList);
    } catch (error) {
      console.error("Error fetching universities: ", error);
    }
  };

  // Fetch container locations for the selected university
  const fetchContainerLocations = async (universityID) => {
    try {
      const querySnapshot = await getDocs(collection(db, `universities/${universityID}/containerLocations`));
      const locations = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        locations.push({
          id: doc.id,
          name: data.name,          // Full container name
          shortName: data.shortName // Short container name for the X-Axis
        });
      });
      setContainerLocations(locations);
    } catch (error) {
      console.error("Error fetching container locations: ", error);
    }
  };

  // For a given container location, fetch its appointments and update the cache.
  // Now we also pull the appointment.type field.
  const fetchAppointmentsForContainer = async (containerLocationID) => {
    try {
      const querySnapshot = await getDocs(collection(db, `universities/${selectedUniversity}/containerLocations/${containerLocationID}/appointments`));
      const appointments = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        appointments[doc.id] = {
          id: doc.id,
          start: data.start.toDate(), // convert to JS Date
          type: data.type           // "Move In" or "Move Out"
        };
      });
      setContainerAppointments(prev => ({ ...prev, [containerLocationID]: appointments }));
    } catch (error) {
      console.error(`Error fetching appointments for container ${containerLocationID}: `, error);
    }
  };

  // When container locations change, fetch appointments for each container location
  useEffect(() => {
    if (containerLocations.length) {
      containerLocations.forEach(location => {
        fetchAppointmentsForContainer(location.id);
      });
    }
  }, [containerLocations, selectedUniversity]);

  // Helper to generate an array of date strings between start and end (inclusive)
  const getDateRange = (start, end) => {
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);
    const dateArray = [];
    const currentDate = new Date(startDateObj);
    while (currentDate <= endDateObj) {
      dateArray.push(currentDate.toISOString().split('T')[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  };

  // Aggregate cumulative registrations by day
  const aggregateCumulativeRegistrations = (users, start, end) => {
    const dateRange = getDateRange(start, end);
    const sortedUsers = users.sort((a, b) => a.registrationDate - b.registrationDate);
    let cumulativeCount = 0;
    let verifiedCount = 0;
    let previousCount = 0;
    let previousVerifiedCount = 0;
    return dateRange.map((dateStr) => {
      const currentDate = new Date(dateStr);
      while (sortedUsers.length && sortedUsers[0].registrationDate <= currentDate) {
        cumulativeCount++;
        const user = sortedUsers.shift();
        if (user.emailVerified === true) {
          verifiedCount++;
        }
      }
      const dailyJoined = cumulativeCount - previousCount;
      const dailyVerified = verifiedCount - previousVerifiedCount;
      previousCount = cumulativeCount;
      previousVerifiedCount = verifiedCount;
      return {
        date: dateStr,
        totalUsers: cumulativeCount,
        dailyJoined,
        emailVerified: verifiedCount,
        dailyVerified,
      };
    });
  };

  // Aggregate plan counts from userDocs based on the storagePlan field
  const aggregatePlanCounts = (users) => {
    const counts = {};
    users.forEach((user) => {
      const plan = user.storagePlan ? user.storagePlan : "Unselected";
      counts[plan] = (counts[plan] || 0) + 1;
    });
    const aggregated = Object.keys(counts).map((planCode) => ({
      planCode,
      name: planCode === "Unselected"
        ? "Unselected"
        : (products[planCode]?.internalName || planCode),
      value: counts[planCode]
    }));
    const order = ["0001", "0002", "0003", "0004"];
    aggregated.sort((a, b) => {
      if (a.planCode === "Unselected" && b.planCode !== "Unselected") return 1;
      if (b.planCode === "Unselected" && a.planCode !== "Unselected") return -1;
      const aIndex = order.indexOf(a.planCode);
      const bIndex = order.indexOf(b.planCode);
      return aIndex - bIndex;
    });
    return aggregated;
  };

  // Aggregate container location data (for the original chart including appointment tooltip)
  const aggregateContainerLocationData = () => {
    const sortedLocations = [...containerLocations].sort((a, b) =>
      a.shortName.localeCompare(b.shortName)
    );

    return sortedLocations.map(location => {
      const moveInCount = userDocs.filter(user => user.moveInContainerLocationID === location.id).length;
      const moveOutCount = userDocs.filter(user => user.moveOutContainerLocationID === location.id).length;

      let appointmentCounts = {};
      const appointments = containerAppointments[location.id];
      if (appointments) {
        Object.values(appointments).forEach(appointment => {
          const apptDate = appointment.start.toISOString().split('T')[0];
          if (!appointmentCounts[apptDate]) {
            appointmentCounts[apptDate] = { moveIn: 0, moveOut: 0 };
          }
          const moveInForAppointment = userDocs.filter(user => user.moveInAppointment === appointment.id).length;
          const moveOutForAppointment = userDocs.filter(user => user.moveOutAppointment === appointment.id).length;
          appointmentCounts[apptDate].moveIn += moveInForAppointment;
          appointmentCounts[apptDate].moveOut += moveOutForAppointment;
        });
      }

      return {
        locationShortName: location.shortName,
        fullName: location.name,
        moveIn: moveInCount,
        moveOut: moveOutCount,
        appointmentCounts
      };
    });
  };

  // Helper: Aggregate appointment chart data for a given type ("Move In" or "Move Out")
  const aggregateAppointmentChartData = (appointmentType) => {
    // For each container, create an object with its short name and counts per appointment day
    const data = containerLocations.map(location => {
      const obj = { containerShortName: location.shortName };
      const appointments = containerAppointments[location.id];
      if (appointments) {
        Object.values(appointments).forEach(appointment => {
          if (appointment.type === appointmentType) {
            const apptDate = appointment.start.toISOString().split('T')[0];
            const count = userDocs.filter(user => {
              return appointmentType === "Move In"
                ? user.moveInAppointment === appointment.id
                : user.moveOutAppointment === appointment.id;
            }).length;
            // Sum if multiple appointments fall on the same day
            obj[apptDate] = (obj[apptDate] || 0) + count;
          }
        });
      }
      return obj;
    });
    return data;
  };

  // Helper: Get union of appointment date keys from an array of data objects
  const getUnionKeys = (data) => {
    const keysSet = new Set();
    data.forEach(item => {
      Object.keys(item).forEach(key => {
        if (key !== 'containerShortName') {
          keysSet.add(key);
        }
      });
    });
    return Array.from(keysSet).sort();
  };

  // Custom tooltip for the cumulative users chart
  const CustomLineTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const dayOfWeek = new Date(label).toLocaleDateString('en-US', { weekday: 'long' });
      const readableDate = getReadableDate(label);
      const totalUsers = payload.find(item => item.dataKey === "totalUsers")?.value;
      const dailyJoined = payload.find(item => item.dataKey === "dailyJoined")?.value;
      const emailVerified = payload.find(item => item.dataKey === "emailVerified")?.value;
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          <p><strong>{dayOfWeek}</strong></p>
          <p>Date: {readableDate}</p>
          <p>Total Registered Users: {totalUsers}</p>
          <p>Users Joined: {dailyJoined}</p>
          <p>Email Verified Users: {emailVerified}</p>
        </div>
      );
    }
    return null;
  };

  // Custom tooltip for the PieChart
  const renderCustomPieTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { planCode, name, value } = payload[0].payload;
      const total = planData.reduce((sum, entry) => sum + entry.value, 0);
      const percent = ((value / total) * 100).toFixed(2);
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          <p><strong>{name} ({planCode})</strong></p>
          <p>Customers: {value}</p>
          <p>Percentage: {percent}%</p>
        </div>
      );
    }
    return null;
  };

  // Custom tooltip for the container location bar chart (with appointment info)
  const renderContainerTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const containerData = payload[0].payload;
      return (
        <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
          <p><strong>{containerData.fullName}</strong></p>
          <p>Move In: {containerData.moveIn}</p>
          <p>Move Out: {containerData.moveOut}</p>
          {containerData.appointmentCounts && Object.keys(containerData.appointmentCounts).length > 0 && (
            <>
              <p style={{ marginTop: '10px', fontWeight: 'bold' }}>Appointments</p>
              {Object.entries(containerData.appointmentCounts).map(([date, counts]) => (
                <p key={date}>
                  {date}: Move In {counts.moveIn}, Move Out {counts.moveOut}
                </p>
              ))}
            </>
          )}
        </div>
      );
    }
    return null;
  };

  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  const formatAppointmentLabel = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    return `${month} ${getOrdinal(day + 1)}`;
  };

  // Initial data fetches on component mount
  useEffect(() => {
    fetchUserDocs();
    fetchProductDocs();
    fetchUniversities();
  }, []);

  // Fetch container locations when the selected university changes.
  useEffect(() => {
    if (selectedUniversity) {
      fetchContainerLocations(selectedUniversity);
      setContainerAppointments({});
    }
  }, [selectedUniversity]);

  // Recalculate cumulative registrations when users or date range change.
  useEffect(() => {
    if (userDocs.length) {
      const cumulativeData = aggregateCumulativeRegistrations([...userDocs], startDate, endDate);
      setCumulativeUserData(cumulativeData);
    }
  }, [userDocs, startDate, endDate]);

  // Recalculate plan counts whenever users or products mapping change.
  useEffect(() => {
    if (userDocs.length) {
      const data = aggregatePlanCounts(userDocs);
      setPlanData(data);
    }
  }, [userDocs, products]);

  // Recalculate container location chart data when container locations, appointments, or users change.
  useEffect(() => {
    if (containerLocations.length) {
      const data = aggregateContainerLocationData();
      setContainerLocationChartData(data);
    } else {
      setContainerLocationChartData([]);
    }
  }, [containerLocations, containerAppointments, userDocs]);

  // Recalculate appointment chart data for Move In and Move Out.
  useEffect(() => {
    const moveInData = aggregateAppointmentChartData("Move In");
    const moveOutData = aggregateAppointmentChartData("Move Out");
    setMoveInChartData(moveInData);
    setMoveOutChartData(moveOutData);
  }, [containerLocations, containerAppointments, userDocs]);

  // Calculate total plan revenue from all selected plans (non-"Unselected")
  const totalPlanRevenue = planData.reduce((sum, plan) => {
    if (plan.planCode !== "Unselected") {
      const price = products[plan.planCode]?.price || 0;
      return sum + (price * plan.value);
    }
    return sum;
  }, 0);

  // Define colors for charts
  const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28DD0'];
  const BAR_COLORS = ['#8884d8', '#82ca9d'];

  // Get union keys for dynamic bars in appointment charts
  const moveInKeys = getUnionKeys(moveInChartData);
  const moveOutKeys = getUnionKeys(moveOutChartData);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      {/* Main stats row */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
            <PeopleIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
            <Typography variant="h6">Registered Users</Typography>
            <Typography variant="body1">{userDocs.length}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
            <AssessmentIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
            <Typography variant="h6">Total Orders</Typography>
            <Typography variant="body1">0</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, textAlign: 'center', backgroundColor: '#e3f2fd', border: '1px solid #0b2b50' }}>
            <AttachMoneyIcon sx={{ fontSize: 40, color: '#0b2b50' }} />
            <Typography variant="h6">Plan Revenue</Typography>
            <Typography variant="body1">Anticipated: ${totalPlanRevenue}</Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Date Range Selection and University Dropdown */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Start Date"
            type="date"
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="End Date"
            type="date"
            fullWidth
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="university-select-label">University</InputLabel>
            <Select
              labelId="university-select-label"
              label="University"
              value={selectedUniversity}
              onChange={(e) => setSelectedUniversity(e.target.value)}
            >
              {universities.map((uni) => (
                <MenuItem key={uni.id} value={uni.id}>
                  {uni.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Analytics Charts */}
      <Grid container spacing={3}>
        {/* Original Container Chart */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Cumulative Registered Users</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={cumulativeUserData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                <YAxis />
                <RechartsTooltip content={<CustomLineTooltip />} />
                <Legend />
                <Line type="monotone" dataKey="totalUsers" name="Total Users" stroke="#82ca9d" />
                <Line type="monotone" dataKey="dailyJoined" name="Daily Joined" stroke="#ff7300" />
                <Line type="monotone" dataKey="emailVerified" name="Email Verified" stroke="#00c49f" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        {/* Pie Chart for User Storage Plans */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>User Plans Breakdown</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={planData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  label
                >
                  {planData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                  ))}
                </Pie>
                <RechartsTooltip content={renderCustomPieTooltip} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        {/* Container Locations with Appointment Tooltip */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Move In/Move Out by Container Location</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={containerLocationChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="locationShortName" />
                <YAxis />
                <RechartsTooltip content={renderContainerTooltip} />
                <Legend />
                <Bar dataKey="moveOut" name="Move Out" fill={BAR_COLORS[1]} />
                <Bar dataKey="moveIn" name="Move In" fill={BAR_COLORS[0]} />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        {/* NEW: Chart for Move In Appointments */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Move In Appointments by Container Location</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={moveInChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="containerShortName" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                {moveInKeys.map((dateKey, index) => (
                  <Bar
                    key={dateKey}
                    dataKey={dateKey}
                    name={formatAppointmentLabel(dateKey)}
                    fill={BAR_COLORS[index % BAR_COLORS.length]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        {/* NEW: Chart for Move Out Appointments */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Move Out Appointments by Container Location</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={moveOutChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="containerShortName" />
                <YAxis />
                <RechartsTooltip />
                <Legend />
                {moveOutKeys.map((dateKey, index) => (
                  <Bar
                    key={dateKey}
                    dataKey={dateKey}
                    name={formatAppointmentLabel(dateKey)}
                    fill={BAR_COLORS[index % BAR_COLORS.length]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

      </Grid>
    </Box>
  );
};

export default AdminDashboard;
